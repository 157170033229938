import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { Information16 } from '@carbon/icons-react'

const CompensateCreditTooltip = () => (
  <Tooltip
    tabIndex={1}
    title={
      <Typography variant="caption" component="p">
        One Compensate Credit equals at least one less tonne of carbon dioxide
        in the atmosphere and can be used to offset 1 tCO
        <sub>2</sub>e of emissions.
      </Typography>
    }
  >
    <Box color="primary.main" component="span">
      <Information16 />
    </Box>
  </Tooltip>
)

export default CompensateCreditTooltip
