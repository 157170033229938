import { API_ENDPOINT_PATHS } from '../constants/api'
import { Calculation } from '../types/calculation.types'
import { Handlers, Request, request } from '../utils/api'
import { mapCalculationResponse } from '../utils/calculation'
import Money from '../utils/formatting/money'

export enum QuickCalculateType {
  Tonnes = 'tonnes',
  Euros = 'euros',
}

export interface QuickCalculateEmissionPayload {
  type: QuickCalculateType
  amount: number // Amount here in rounded euros
  handlers?: Handlers
}

export interface GetCalculationEmissionMassPayload {
  emissions: number // Emissions in gCO<sub>2</sub>e
}

export interface GetCalculationEmissionPricePayload {
  amount: number // Amount (in euro cents)
}

export interface GetCalculationEmissionIndexPayload {
  value: number // Value of in EUR cents of the emission calculation requested
  type: string // Name of emission factor to use
  description?: string // Subject of the calculation, in human-readable format provided by the customer
}

export interface GetCalculationEmissionProductPayload {
  quantity: number
  type: string // Name of emission factor to use
  description?: string // Subject of the calculation, in human-readable format provided by the customer
}

/**
 * Takes the amount in tonnes of CO2 or euros and converts the unit
 * to the relevant subunit before passing to the API functions.
 */
export const quickCalculateEmission = ({
  type,
  amount,
}: QuickCalculateEmissionPayload): Promise<Calculation> =>
  type === QuickCalculateType.Euros
    ? getCalculationEmissionPrice(new Money(amount).fromSubunit(100).amount)
    : getCalculationEmissionMass(parseFloat(amount.toFixed(2)) * 1000000)

export const getCalculationEmissionMass = async (
  emissions: number, // Emissions in grams of CO2e
): Promise<Calculation> => {
  // rounded in 2dp tonnes
  const body: Request<GetCalculationEmissionMassPayload> = {
    type: 'POST',
    path: API_ENDPOINT_PATHS.EMISSIONS_MASS,
    data: {
      emissions,
    },
  }
  const calculation = await request(body)
  return mapCalculationResponse(calculation)
}

const getCalculationEmissionPrice = async (
  amount: number,
): Promise<Calculation> => {
  const body: Request<GetCalculationEmissionPricePayload> = {
    type: 'POST',
    path: API_ENDPOINT_PATHS.EMISSIONS_PRICE,
    data: {
      amount,
    },
  }
  const calculation = await request(body)
  return mapCalculationResponse(calculation)
}

export const getCalculationEmissionIndex = async (
  value: number,
  type: string,
  description?: string,
): Promise<Calculation> => {
  const body: Request<GetCalculationEmissionIndexPayload> = {
    type: 'POST',
    path: API_ENDPOINT_PATHS.EMISSIONS_INDEX,
    data: {
      value,
      type,
      description,
    },
  }
  const calculation = await request(body)
  return mapCalculationResponse(calculation)
}

export const getCalculationEmissionProduct = async (
  quantity: number,
  type: string,
  description?: string,
): Promise<Calculation> => {
  const body: Request<GetCalculationEmissionProductPayload> = {
    type: 'POST',
    path: API_ENDPOINT_PATHS.EMISSIONS_PRODUCT,
    data: {
      quantity,
      type,
      description,
    },
  }
  const calculation = await request(body)
  return mapCalculationResponse(calculation)
}
