import React from 'react'
import { Link } from 'gatsby'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import MuiLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import { SxProps } from '@mui/material/styles'

import useAuthStore, { userSelector } from '../store/auth.store'
import useUiStore, {
  openAuthPopupSelector,
  setContactFormSelector,
} from '../store/ui.store'
import {
  FOOTER_LINKS,
  FOOTER_LINKS_MINIMAL,
  AUTHENTICATED_USER_LINKS,
  FOOTER_BOTTOM_LINKS,
} from '../constants/navigation'
import { EXTERNAL_LINK_PROPS } from '../constants/links'
import { signOut } from '../utils/auth'
import Logo from './Logo'
import ChangeCookieHubConsent from './CookieConsentHub'

interface Props {
  showMinimalLayout?: boolean
}

const FOOTER_SX: SxProps = {
  mt: 8,
  pt: 8,
  px: 4,
  bgcolor: 'background.paperDark',
  color: 'textLight.primary',
  textAlign: ['center', 'center', 'left'],
}

const FOOTER_BOTTOM_SX: SxProps = {
  mt: 12,
  py: 2,
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: 'textLight.primary',
  color: 'textLight.secondary',
}

const Footer = ({ showMinimalLayout = false }: Props) => {
  const handleOpenSignIn = useUiStore(openAuthPopupSelector)
  const setContactForm = useUiStore(setContactFormSelector)
  const user = useAuthStore(userSelector)
  const isAnonymous = user?.isAnonymous ?? true

  const handleClickContactUs = () => {
    setContactForm({
      metadata: {
        Path: window.location.pathname,
      },
    })
  }

  return (
    <Box component="footer" sx={FOOTER_SX}>
      <Container disableGutters>
        <Grid container spacing={[4, 12]} maxWidth="xl">
          <Grid item xs={12} md={6}>
            <Stack spacing={4} alignItems={{ xs: 'center', md: 'flex-start' }}>
              <Logo />

              <Typography
                variant="body2"
                color="textLight.secondary"
                sx={{ maxWidth: 400 }}
              >
                Compensate combats climate change by offering easy access to
                high-quality carbon projects.
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            lg={2}
            sx={{ color: 'textLight.secondary' }}
          >
            <Stack spacing={1} alignItems={{ xs: 'center', md: 'flex-start' }}>
              <Stack component="nav" alignItems="start">
                <Box component="ul" sx={{ m: 0, p: 0, listStyleType: 'none' }}>
                  {(showMinimalLayout
                    ? FOOTER_LINKS_MINIMAL
                    : FOOTER_LINKS
                  ).map(({ path, title, isExternal = false }) => (
                    <li key={path}>
                      <MuiLink
                        typography="body2"
                        color="inherit"
                        underline="hover"
                        {...(isExternal
                          ? { ...(EXTERNAL_LINK_PROPS as any), href: path }
                          : { component: Link, to: path })}
                      >
                        {title}
                      </MuiLink>
                    </li>
                  ))}
                  <li>
                    <MuiLink
                      component="button"
                      typography="body2"
                      color="inherit"
                      underline="hover"
                      onClick={handleClickContactUs}
                    >
                      Contact us
                    </MuiLink>
                  </li>
                </Box>
              </Stack>
            </Stack>
          </Grid>

          {!showMinimalLayout && (
            <Grid
              item
              xs={12}
              md={3}
              lg={3}
              sx={{ color: 'textLight.secondary' }}
            >
              <Stack
                spacing={1}
                alignItems={{ xs: 'center', md: 'flex-start' }}
              >
                {isAnonymous ? (
                  <>
                    <Button
                      size="small"
                      variant="outlined"
                      color="inherit"
                      onClick={handleOpenSignIn}
                    >
                      Log in
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      size="small"
                      variant="outlined"
                      color="inherit"
                      onClick={signOut}
                    >
                      Log out
                    </Button>

                    <Box
                      component="ul"
                      sx={{ m: 0, p: 0, listStyleType: 'none' }}
                    >
                      {AUTHENTICATED_USER_LINKS.map(
                        ({ path, title, isExternal = false }) => (
                          <li key={path}>
                            <MuiLink
                              typography="body2"
                              color="inherit"
                              underline="hover"
                              {...(isExternal
                                ? {
                                    ...(EXTERNAL_LINK_PROPS as any),
                                    href: path,
                                  }
                                : { component: Link, to: path })}
                            >
                              {title}
                            </MuiLink>
                          </li>
                        ),
                      )}
                    </Box>
                  </>
                )}
              </Stack>
            </Grid>
          )}
        </Grid>

        <Box sx={FOOTER_BOTTOM_SX}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={1}
            justifyContent={{ xs: 'start', md: 'space-between' }}
            alignItems={{ xs: 'center', md: 'flex-start' }}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={{ xs: 1, md: 2 }}
              alignItems={{ xs: 'center', md: 'start' }}
            >
              {FOOTER_BOTTOM_LINKS.map(
                ({ path, title, isExternal = false }) => (
                  <MuiLink
                    key={path}
                    typography="caption"
                    color="textLight.primary"
                    fontWeight="bold"
                    underline="hover"
                    {...(isExternal
                      ? { ...(EXTERNAL_LINK_PROPS as any), href: path }
                      : { component: Link, to: path })}
                  >
                    {title}
                  </MuiLink>
                ),
              )}

              <ChangeCookieHubConsent
                typography="caption"
                color="textLight.primary"
                fontWeight="bold"
                underline="hover"
              />
            </Stack>

            <Typography variant="caption">
              © Compensate {new Date().getFullYear()}. All rights reserved.
            </Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
