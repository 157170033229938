const STATIC_BUCKET = 'https://storage.googleapis.com/coco-api-static-files/'

/**
 * Returns the URL of an asset in Cloud Storage
 * `path` is normalized by removing the leading slash
 *
 * @param path The path to the asset, e.g. /images/example.png
 */
export const getStaticAsset = (path: string) =>
  `${STATIC_BUCKET}${path.replace(/^\//, '')}`

export default getStaticAsset
