import React from 'react'
import { Button, DialogActions, DialogContentText, Stack } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import useUiStore, {
  addSnackbarSelector,
  contactFormSelector,
  setContactFormSelector,
} from '../store/ui.store'
import DialogWrapper from './DialogWrapper'
import {
  ContactFormSchema,
  CONTACT_CATEGORY,
  CONTACT_FORM_SCHEMA,
} from '../types/contact-form'
import ControlledTextField from './form/ControlledTextField'
import ControlledSelect from './form/ControlledSelect'
import { sendContactForm } from '../apis/feedback.api'

const ContactFormDialog = () => {
  const contactForm = useUiStore(contactFormSelector)
  const setContactForm = useUiStore(setContactFormSelector)

  const addSnackbar = useUiStore(addSnackbarSelector)

  const {
    watch,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm<ContactFormSchema>({
    resolver: yupResolver(CONTACT_FORM_SCHEMA),
  })

  const watchCategory = watch('category', contactForm?.category)

  React.useEffect(() => {
    if (contactForm?.category) {
      setValue('category', contactForm?.category)
    }
  }, [contactForm?.category])

  React.useEffect(() => {
    reset()
  }, [isSubmitSuccessful])

  async function onSubmit(form: ContactFormSchema) {
    try {
      await sendContactForm({ ...form, metadata: contactForm?.metadata ?? {} })
      setContactForm(undefined)
      addSnackbar(
        "Thanks for reaching out, we'll be in touch soon!",
        'success',
        `Contact form submitted`,
      )
    } catch (error: any) {
      addSnackbar(error.message, 'error', `Failed to send your request.`)
    }
  }

  return (
    <DialogWrapper
      onClose={() => setContactForm(undefined)}
      open={!!contactForm}
      maxWidth="sm"
      aria-label="contact form"
      title="Contact us"
    >
      <Stack
        noValidate
        component="form"
        spacing={1}
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContentText paragraph sx={{ textAlign: 'center' }}>
          We’re here to help, please leave your contact details and we’ll be in
          touch.
        </DialogContentText>

        <ControlledTextField
          control={control}
          name="name"
          label="Your name"
          error={!!errors.name}
          helperText={errors.name?.message}
          inputProps={{
            'aria-label': 'name',
            maxLength: 500,
          }}
          required
        />

        <ControlledTextField
          control={control}
          name="email"
          label="Contact email"
          error={!!errors.email}
          helperText={errors.email?.message}
          inputProps={{
            'aria-label': 'email',
            maxLength: 500,
          }}
          required
        />

        <ControlledTextField
          control={control}
          name="companyName"
          label="Company name"
          error={!!errors.companyName}
          helperText={errors.companyName?.message}
          inputProps={{
            'aria-label': 'companyName',
            maxLength: 500,
          }}
        />

        <ControlledSelect
          control={control}
          name="category"
          label="What’s on your mind?"
          defaultValue={contactForm?.category}
          options={CONTACT_CATEGORY}
          error={!!errors.category}
          helperText={errors.category?.message}
          inputProps={{
            'aria-label': 'category',
          }}
          fullWidth
          required
        />

        <ControlledTextField
          control={control}
          name="message"
          label={
            watchCategory === 'invoice'
              ? 'Your invoicing details'
              : 'How can we help?'
          }
          error={!!errors.message}
          helperText={errors.message?.message}
          inputProps={{
            'aria-label': 'message',
            maxLength: 500,
          }}
          multiline
          rows={3}
          required
        />

        <DialogActions sx={{ p: [1] }}>
          <Button
            variant="text"
            color="inherit"
            size="small"
            onClick={() => setContactForm(undefined)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="small"
            data-gaid="submit_store_contact_form"
          >
            Submit
          </Button>
        </DialogActions>
      </Stack>
    </DialogWrapper>
  )
}

export default ContactFormDialog
