import React, { useState } from 'react'
import {
  AlertTitle,
  Stack,
  Button,
  Box,
  BoxProps,
  Typography,
  CircularProgress,
  DialogProps,
} from '@mui/material'
import { Email32 } from '@carbon/icons-react'

import GoogleLogo from '../../assets/svg/google-logo.svg'
import { continueWithGoogle } from '../../utils/auth'
import useUiStore, { addSnackbarSelector } from '../../store/ui.store'
import { Flow } from './flow.types'
import DialogWrapper from '../DialogWrapper'
import InitialEmailForm from './InitialEmailForm'
import ContinuedWithEmailForm from './ContinuedWithEmailForm'
import ForgotPasswordForm from './ForgotPasswordForm'

function getTitle(flow: Flow) {
  if (flow === 'INITIAL') {
    return <>Log in to your account</>
  }

  if (flow === 'SIGN_IN') {
    return (
      <>
        Welcome back,
        <br />
        log in to your account
      </>
    )
  }

  if (flow === 'SIGN_UP') {
    return <>We couldn't find an account with that email.</>
  }

  if (flow === 'FORGOT_PASSWORD') {
    return (
      <>
        Enter your email to
        <br /> reset your password
      </>
    )
  }

  if (flow === 'FORGOT_PASSWORD_SUCCESS') {
    return (
      <>
        <Box component={Email32} sx={{ width: 42, height: 42 }} />
        <br /> An email is on the way
      </>
    )
  }
}

const Divider = (props: BoxProps) => (
  <Box
    component="hr"
    sx={{
      flex: '1 1 auto',
      color: 'inherit',
      opacity: 0.6,
      '&:first-of-type': {
        mr: 1,
      },
      '&:last-of-type': {
        ml: 1,
      },
    }}
    {...props}
  />
)

interface Props extends Omit<DialogProps, 'title'> {
  onClose?: () => void
  /** Called upon successful sign in/ up with email, will not be called for Google sign in */
  onComplete?: () => void
  /** Whether the user is allowed to close the popup */
  dismissible?: boolean
}

/**
 * If the user signs in or up via email, this modal presents a two step process:
 *   1. The user enters their email and clicks "Next".
 *   2. Depending on whether that email is in use, the user is directed to
 *      the SIGN_IN flow or SIGN_UP flow.
 */
const SignInOrUp = ({
  onClose,
  onComplete,
  dismissible = true,
  sx,
  ...dialogProps
}: Props) => {
  const addSnackbar = useUiStore(addSnackbarSelector)

  const [email, setEmail] = useState<string>()
  const [flow, setFlow] = useState<Flow>('INITIAL')
  const [isRedirecting, setIsRedirecting] = useState(false)

  function handleContinueWithEmail(flow: Flow, email: string) {
    setFlow(flow)
    setEmail(email)
  }

  async function handleContinueWithGoogle() {
    setIsRedirecting(true)

    try {
      await continueWithGoogle()
    } catch (error: any) {
      // No-op
    } finally {
      setIsRedirecting(false)
    }
  }

  function handleCompleteEmailFlow() {
    if (flow === 'SIGN_IN') {
      addSnackbar(
        <>
          <AlertTitle>Welcome back to Compensate's Carbon Store</AlertTitle>
          You're logged in as {email}
        </>,
      )
    } else if (flow === 'SIGN_UP') {
      addSnackbar(
        <>
          <AlertTitle>Welcome to Compensate's Carbon Store</AlertTitle>
          You've created an account with the email {email}
        </>,
      )
    }

    onComplete?.()
    onClose?.()
  }

  function handleForgotPasswordSuccess(email: string) {
    setFlow('FORGOT_PASSWORD_SUCCESS')
    setEmail(email)
  }

  return (
    <DialogWrapper
      onClose={dismissible ? onClose : undefined}
      maxWidth="xs"
      title={getTitle(flow)}
      sx={{ textAlign: 'center', ...sx }}
      TransitionProps={{ onExited: () => setFlow('INITIAL') }}
      {...dialogProps}
    >
      {flow === 'INITIAL' && (
        <>
          <Stack spacing={2}>
            <Button
              variant="outlined"
              color="inherit"
              startIcon={<GoogleLogo />}
              onClick={handleContinueWithGoogle}
              disabled={isRedirecting}
              endIcon={isRedirecting && <CircularProgress />}
              sx={(theme) => ({
                color: theme.palette.grey['700'],
                borderColor: theme.palette.grey['300'],
              })}
            >
              <Box
                component="span"
                sx={{
                  fontWeight: 500,
                  fontFamily: 'system-ui,-apple-system,Roboto,sans-serif',
                  flex: '1 1 auto',
                }}
              >
                Continue with Google
              </Box>
            </Button>

            <Box display="flex" alignItems="center" color="text.label">
              <Divider />
              <Typography variant="body2" color="inherit">
                Or continue with email
              </Typography>
              <Divider />
            </Box>

            <InitialEmailForm
              initialValue={email}
              onContinue={handleContinueWithEmail}
            />
          </Stack>
        </>
      )}

      {!!email && (flow === 'SIGN_IN' || flow === 'SIGN_UP') && (
        <ContinuedWithEmailForm
          email={email}
          flow={flow}
          onBack={() => setFlow('INITIAL')}
          onForgotPassword={() => setFlow('FORGOT_PASSWORD')}
          onComplete={handleCompleteEmailFlow}
        />
      )}

      {flow === 'FORGOT_PASSWORD' && (
        <ForgotPasswordForm
          initialEmailValue={email}
          onBack={() => setFlow('SIGN_IN')}
          onContinue={handleForgotPasswordSuccess}
        />
      )}

      {flow === 'FORGOT_PASSWORD_SUCCESS' && (
        <Stack spacing={2}>
          <Typography variant="body2" color="inherit">
            We&apos;ve sent a password reset email{email ? ` to ${email}` : ''}.
            If it doesn&apos;t arrive, be sure to check your spam folder.
          </Typography>

          <Button variant="text" onClick={() => setFlow('SIGN_IN')}>
            Back to log in
          </Button>
        </Stack>
      )}
    </DialogWrapper>
  )
}

export default SignInOrUp
