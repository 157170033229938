import { LinkProps } from '@mui/material/Link'

/** GENERAL */
export const COMPENSATE_COM_LINK = 'https://www.compensate.com'
export const TOU_LINK = `${COMPENSATE_COM_LINK}/terms-of-use-carbon-store`
export const PRIVACY_STATEMENT_LINK = `${COMPENSATE_COM_LINK}/privacy-statement`
export const CARBON_STORE_LINK = 'https://store.compensate.com'

/** API */
export const API_DOCS_LINK = 'https://api.compensate.com/docs/'
export const API_DOCS_TESTING_LINK = `${API_DOCS_LINK}#testing`
export const API_DOCS_AUTHENTICATION_LINK = `${API_DOCS_LINK}#tag/Authentication`

/** Helper props */
export const EXTERNAL_LINK_PROPS: Partial<LinkProps> = {
  target: '_blank',
  rel: 'noopener',
}
export const EXTERNAL_DOWNLOAD_LINK_PROPS: Partial<LinkProps> = {
  target: '_self',
  rel: 'noopener',
}
