import { License, APIAccessLevel } from '../types/license.types'

export function getApiDashboardAccess(
  license: License | undefined,
): APIAccessLevel {
  if (
    license &&
    license.type === 'custom' &&
    license.invoicing_type === 'default'
  ) {
    return APIAccessLevel.FULL
  } else if (
    license &&
    license.type === 'custom' &&
    license.invoicing_type === 'custom'
  ) {
    return APIAccessLevel.PARTIAL
  } else {
    return APIAccessLevel.NONE
  }
}
