import { API_ENDPOINT_PATHS } from '../constants/api'
import { License } from '../types/license.types'
import { request } from '../utils/api'

export const getLicense = async (): Promise<License> => {
  return await request({
    type: 'GET',
    path: API_ENDPOINT_PATHS.LICENSE,
  })
}
