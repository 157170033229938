import {
  PlanePrivate32,
  CropGrowth32,
  Flash32,
  TemperatureHot32,
  Devices32,
  Clean32,
  Store32,
  Bus32,
} from '@carbon/icons-react'
import { PURCHASE_TYPES } from '../components/calculators/purchased-goods/common.constants'

import { Calculation, CalculationResponse } from '../types/calculation.types'
import DateFormatter from './formatting/date'
import Emissions from './formatting/emissions'
import Money from './formatting/money'

export const mapCalculationResponse = (
  calculation: CalculationResponse,
): Calculation => ({
  ...calculation,
  created_at: new DateFormatter(calculation.created_at),
  updated_at: new DateFormatter(calculation.updated_at),
  value: new Money(calculation.value),
  emissions: {
    upstream: new Emissions(calculation.emissions.upstream),
    usage: new Emissions(calculation.emissions.usage),
    total: new Emissions(
      calculation.emissions.usage + calculation.emissions.upstream,
    ),
  },
})

export const combineCalculations = (
  calculations: Calculation[],
): Calculation => {
  return calculations.reduce((acc, calculation) => {
    const combinedCalculation: Calculation = {
      ...calculation,
      value: new Money(acc.value.amount + calculation.value.amount),
      emissions: {
        upstream: new Emissions(
          acc.emissions.upstream.amount + calculation.emissions.upstream.amount,
        ),
        usage: new Emissions(
          acc.emissions.usage.amount + calculation.emissions.usage.amount,
        ),
        total: new Emissions(
          acc.emissions.total.amount + calculation.emissions.total.amount,
        ),
      },
      id: `combined-${calculation.calculation_source}`,
      type: calculation.type,
      calculation_source: calculation.calculation_source,
      description: 'Digital devices',
      params: undefined,
    }

    return combinedCalculation
  })
}

export const sumCalculationsEmissions = (calculations: Calculation[]): number =>
  calculations?.reduce(
    (sum: number, calculation: Calculation) =>
      sum + calculation.emissions.total.amount,
    0,
  ) ?? 0

export function getNameFromCalculation(calculation: Calculation) {
  switch (calculation.calculation_source) {
    case 'flight':
      return 'Flight emissions offset'
    case 'district_heating':
      return 'District heating emissions offset'
    case 'electricity':
      return 'Electricity emissions offset'
    case 'purchases_and_goods':
      return 'Digital devices offset'
    case 'index':
      if (
        PURCHASE_TYPES.services.find((type) => type.value === calculation.type)
      ) {
        return 'Purchased services offset'
      }
      if (
        PURCHASE_TYPES.products.find((type) => type.value === calculation.type)
      ) {
        return 'Purchased products offset'
      }
      if (
        PURCHASE_TYPES.travel.find((type) => type.value === calculation.type)
      ) {
        return 'Travel and transportation offset'
      }
    case 'price':
    case 'mass':
    case 'other':
    case '':
      return `Carbon offset by ${
        calculation?.type === 'price_emissions' ? 'Euros' : 'Tonnes'
      }`
  }

  return calculation.calculation_source || calculation.type
}

export function getIconFromCalculation(calculation: Calculation) {
  switch (calculation.calculation_source) {
    case 'flight':
      return PlanePrivate32
    case 'district_heating':
      return TemperatureHot32
    case 'electricity':
      return Flash32
    case 'purchases_and_goods':
      return Devices32
    case 'index':
      if (
        PURCHASE_TYPES.services.find((type) => type.value === calculation.type)
      ) {
        return Clean32
      }
      if (
        PURCHASE_TYPES.products.find((type) => type.value === calculation.type)
      ) {
        return Store32
      }
      if (
        PURCHASE_TYPES.travel.find((type) => type.value === calculation.type)
      ) {
        return Bus32
      }
    case 'price':
    case 'mass':
    case 'other':
    case '':
      return CropGrowth32
  }
}

export function getCompensateCredits(calculation: Calculation) {
  return calculation.emissions.total
    .fromGrams()
    .toString({ withUnit: false, decimals: 2 })
}
