import {
  ref,
  get,
  set,
  push,
  update,
  onValue,
  DataSnapshot,
  remove,
  Query,
} from 'firebase/database'
import { StoredUser } from '../store/auth.store'

import { Calculation } from '../types/calculation.types'
import { mapFirebaseCartToCartItems } from '../utils/cart'
import firebase from '../utils/firebase'

const MAX_CART_ITEMS = 40

export interface Cart {
  // <Cart item ID>: <Calculation ID>
  [itemId: string]: string
}

export interface CartItem {
  calculationId: string
  calculation?: Calculation
  itemId?: string
}

export async function addToCart(calculationId: string) {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid

  if (!userId) {
    throw Error('User not found')
  }

  const cartRef = ref(db, `users/${userId}/cart`)
  const calculationsSnapshot = await get(cartRef)
  const calculations = calculationsSnapshot.val()

  /**
   * The user already has this calculation in their cart.
   *
   * We should always clear a calculation after adding to the cart, so this _shouldn't_
   * happen. But just in case it does, prevent duplicate items in the cart and inform
   * the user.
   */
  if (calculations && Object.values(calculations).includes(calculationId)) {
    throw new Error(
      "Oops! That item is already in your cart, if you'd like to add another please refresh the page.",
    )
  }

  // The user has hit the max number of cart items
  if (calculations && Object.values(calculations).length >= MAX_CART_ITEMS) {
    throw new Error(
      `You can have a maximum of ${MAX_CART_ITEMS} items in your cart, please check out first and then place a new order.`,
    )
  }

  const newCalculationRef = push(cartRef)

  return set(newCalculationRef, calculationId)
}

export function removeFromCart(cartItemId: string) {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid
  if (!userId) {
    throw Error('User not found')
  }

  return remove(ref(db, `users/${userId}/cart/${cartItemId}`))
}

/**
 * Removing the full cart from the store
 *
 * If you provide `calculationIds` it will only remove the cart if it matches the ids
 */
export async function removeCart(calculationIds?: string[]) {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid
  if (!userId) {
    throw Error('User not found')
  }

  const cartRef = ref(db, `users/${userId}/cart`)

  // If `calculationIds` exists, it will only remove the matching ids
  if (calculationIds) {
    const storedCart = await get(cartRef)
    const matchingCartItemIds = mapFirebaseCartToCartItems(storedCart.val())
      .filter((item) => calculationIds.includes(item.calculationId))
      .map((item) => item.itemId)
      .filter((cartItemId) => !!cartItemId) as string[]
    if (matchingCartItemIds.length) {
      // Create object with all paths to be set to null. This way we can delete multiple items in one request. This will trigger the listener only once.
      const result = matchingCartItemIds.reduce((r, key) => {
        return {
          ...r,
          [`/${key}`]: null,
        }
      }, {})
      return update(cartRef, result)
    }
  } else {
    return remove(cartRef)
  }
}

export function getCart(onChange: (snapshot: DataSnapshot) => void) {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid
  if (!userId) {
    throw Error('User not found')
  }

  const cartRef = ref(db, `users/${userId}/cart`)

  return onValue(cartRef, onChange)
}

export function getCartQuery({ uid }: StoredUser): Query {
  const db = firebase.getDatabase()
  return ref(db, `users/${uid}/cart`)
}
