import { initializeApp, FirebaseApp } from 'firebase/app'
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth'
import {
  FunctionsError,
  getFunctions,
  httpsCallable,
  Functions,
  connectFunctionsEmulator,
} from 'firebase/functions'
import {
  Database,
  getDatabase,
  connectDatabaseEmulator,
} from 'firebase/database'

type LogSeverity =
  | 'DEBUG'
  | 'INFO'
  | 'NOTICE'
  | 'WARNING'
  | 'ERROR'
  | 'CRITICAL'
  | 'ALERT'
  | 'EMERGENCY'

export interface LogEntry {
  severity: LogSeverity
  message?: string
  [key: string]: any
}

class Firebase {
  private app?: FirebaseApp
  private auth?: Auth
  private database?: Database
  private _functions?: Functions

  /**
   * Initializes the Firebase app with config defined in the environment
   */
  private init(): FirebaseApp {
    if (this.app) {
      return this.app
    }

    this.app = initializeApp({
      apiKey: process.env.GATSBY_FIREBASE_API_KEY,
      authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
      storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.GATSBY_FIREBASE_APP_ID,
    })

    return this.app
  }

  private getFunctions(): Functions {
    if (this._functions) {
      return this._functions
    }

    this._functions = getFunctions(
      this.init(),
      process.env.GATSBY_FIREBASE_FUNCTIONS_REGION,
    )

    if (
      window.location.hostname === 'localhost' &&
      process.env.GATSBY_FIREBASE_EMULATORS === 'true'
    ) {
      connectFunctionsEmulator(this._functions, 'localhost', 5001)
    }

    return this._functions
  }

  /**
   * Returns the Firebase Auth service and initializes the Firebase app if needed
   */
  public getAuth(): Auth {
    if (this.auth) {
      return this.auth
    }

    this.auth = getAuth(this.init())

    if (
      window.location.hostname === 'localhost' &&
      process.env.GATSBY_FIREBASE_EMULATORS === 'true'
    ) {
      connectAuthEmulator(this.auth, 'http://localhost:9099')
    }

    return this.auth
  }

  /**
   * Returns a JSON Web Token (JWT) used to identify the user to our Firebase service
   */
  public async getToken(): Promise<string | undefined> {
    const auth = this.getAuth()

    if (!auth.currentUser) {
      return undefined
    }

    return auth.currentUser.getIdToken()
  }

  /**
   * Returns the Firebase Database and initializes the Firebase app if needed
   */
  public getDatabase(): Database {
    if (this.database) {
      return this.database
    }

    this.database = getDatabase(this.init())

    if (
      window.location.hostname === 'localhost' &&
      process.env.GATSBY_FIREBASE_EMULATORS === 'true'
    ) {
      // Point to the RTDB emulator running on localhost.
      connectDatabaseEmulator(this.database, 'localhost', 9000)
    }

    return this.database
  }

  public functions() {
    const functions = this.getFunctions()

    return {
      logger: httpsCallable<LogEntry, FunctionsError | boolean>(
        functions,
        'logger',
      ),
    }
  }
}

const firebase = new Firebase()

export default firebase
