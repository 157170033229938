import { isBrowser } from '@carbon/shared'

/**
 * Bot user agents should be added to the below regex as they're discovered
 * within our platform. We should ve very careful when adding new user agents
 * to the bot list, if a real user is accidentally blacklisted they'll be screwed.
 */
export const isBot = isBrowser
  ? /AdsBot-Google|adsbot|googlebot/i.test(window.navigator?.userAgent ?? '')
  : false

export function isInternalRoute(locationPath: string) {
  return locationPath.includes('/internal/')
}

export { isBrowser, isDev } from '@carbon/shared'
