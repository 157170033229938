export interface License {
  type: 'light' | 'custom'
  status: 'active'
  invoicing_type: 'none' | 'custom' | 'default'
}

/**
 *  Defines the access level to API dashboard content for customers.
 *  For example a customer with an invoicing type of custom doesn't need to see upcoming invoice related content.
 */
export enum APIAccessLevel {
  FULL,
  PARTIAL,
  NONE,
}
