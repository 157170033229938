import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { SxProps, Theme } from '@mui/material'

import DialogHero from './DialogHero'

interface Props extends Omit<DialogProps, 'title'> {
  contentSx?: SxProps<Theme>
  title?: React.ReactNode
  titleSx?: SxProps<Theme>
  titleVariant?: DialogTitleProps['variant']
  hasContentPadding?: boolean
}

const DialogWrapper = ({
  children,
  contentSx,
  title,
  titleVariant = 'h4',
  titleSx,
  hasContentPadding = true,
  onClose,
  sx,
  ...props
}: Props) => (
  <Dialog
    onClose={onClose}
    sx={{ overflow: 'hidden', ...sx }}
    maxWidth="md"
    fullWidth
    scroll="paper"
    {...props}
  >
    <DialogHero onClose={onClose} />

    {title && (
      <DialogTitle
        sx={{ pb: 2, px: [4, 8], pt: 6, textAlign: 'center', ...titleSx }}
        variant={titleVariant}
      >
        {title}
      </DialogTitle>
    )}

    <DialogContent sx={{ p: hasContentPadding ? [4, 8] : [0], ...contentSx }}>
      {children}
    </DialogContent>
  </Dialog>
)

export default DialogWrapper
