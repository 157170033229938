import { API_ENDPOINT_PATHS } from '../constants/api'
import { Request, request } from '../utils/api'

export interface RequestAccessPayload {
  email: string
  name?: string
}

export const requestAccess = (email: string, name?: string) => {
  const body: Request<RequestAccessPayload> = {
    type: 'POST',
    path: API_ENDPOINT_PATHS.CUSTOMER_REQUEST_ACCESS,
    data: {
      email,
      name,
    },
  }

  return request(body)
}

/**
 * After converting an anonymous user in Firebase
 * this should be called to convert the user to registered
 * within the platform. This enables welcome emails to be sent.
 */
export const registerPlatformUser = () => {
  const body: Request = {
    type: 'POST',
    path: API_ENDPOINT_PATHS.CUSTOMER_REGISTER,
  }

  return request(body)
}
