interface FormatOptions {
  decimals?: number
}

/**
 * Helper for formatting monetary values.
 * Use this with subunits by chaining fromSubunit, e.g.
 * new Money(100).fromSubunit().toString() => "1,00 €"
 */
class Money {
  constructor(
    public amount: number,
    private currency: string = 'EUR',
    private locale?: string,
  ) {}

  fromSubunit(fraction: number = 1 / 100): Money {
    return new Money(this.amount * fraction, this.currency, this.locale)
  }

  toString(options: FormatOptions = {}): string {
    return this.amount.toLocaleString(this.locale, {
      style: 'currency',
      currency: this.currency,
      maximumFractionDigits: options.decimals,
    })
  }
}

export default Money
