import { DataSnapshot, onValue, Query } from 'firebase/database'
import { useEffect, useState } from 'react'

import useAuthStore, { StoredUser, userSelector } from '../store/auth.store'
import { Idle, Loading, Rejected, RequestState, Resolved } from '../utils/api'

/**
 * Helper hook to fetch data from the Firebase Realtime Database reactively,
 * when data changes in the Realtime DB the new value will be updated. Return
 * values conform to the same structure of useRequest.
 *
 * A value of `null` indicates the query location has no data
 */
function useRealtimeDBOnChange<T>(
  getQuery: (user: StoredUser) => Query,
  defaultValue: T | null = null,
  onlyOnce: boolean = false,
) {
  const user = useAuthStore(userSelector)
  const [state, setState] = useState<RequestState<T | null>>(new Idle())

  function onChange(snapshot: DataSnapshot) {
    const value = snapshot.val()

    setState(new Resolved<T | null>(value === null ? defaultValue : value))
  }

  function onError(error: Error) {
    setState(new Rejected(error))
  }

  useEffect(() => {
    if (!user) {
      return
    }

    setState(new Loading())

    const unsubscribe = onValue(getQuery(user), onChange, onError, { onlyOnce })

    return () => {
      unsubscribe?.()
    }
  }, [user])

  return state
}

export default useRealtimeDBOnChange
