import React from 'react'

import firebase from '../utils/firebase'
import useAuthStore from '../store/auth.store'
import ErrorPage from './ErrorPage'

class ErrorBoundary extends React.Component<
  React.PropsWithChildren,
  { hasError: boolean }
> {
  constructor(props: React.PropsWithChildren) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true }
  }

  async componentDidCatch(error: unknown, errorInfo: React.ErrorInfo) {
    const uid = useAuthStore.getState().user?.uid
    const message = error instanceof Error ? error.message : 'Unknown error'

    try {
      await firebase.functions().logger({
        severity: 'ERROR',
        message: `An uncaught frontend error occurred: ${message}`,
        uid,
        page: window?.location?.pathname,
        error,
        trace: errorInfo.componentStack,
      })
    } catch (error) {
      // No-op
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />
    }

    return this.props.children
  }
}

export default ErrorBoundary
