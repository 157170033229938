import React from 'react'
import { PageProps } from 'gatsby'
import { Box, Container, LinearProgress } from '@mui/material'

import TopBar from './TopBar'
import Footer from './Footer'
import Snackbars from './Snackbars'
import useAuthStore, { statusSelector } from '../store/auth.store'
import { isInternalRoute } from '../utils/environment'

interface Props extends Omit<PageProps, 'children'> {
  requiresAuthFlow: boolean
  children: React.ReactNode
}

export const shouldShowMinimalLayout = (locationPath: string): boolean => {
  /**
   * A minimal layout is shown for campaign pages, where the user is directed
   * straight to a minimal checkout with no user context menu or sign up actions.
   */
  if (locationPath.indexOf('/c/') > -1 || isInternalRoute(locationPath)) {
    return true
  }

  return false
}

const LayoutMinimal = ({ requiresAuthFlow, children }: Props) => {
  const status = useAuthStore(statusSelector)

  return (
    <>
      {status === 'LOADING' && requiresAuthFlow && (
        <LinearProgress
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 'tooltip',
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          // Ensure Navigation spacers are rendered in the correct position
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <Snackbars />
        <TopBar showMinimalLayout />

        <Box sx={{ flexGrow: 1, zIndex: 1 }}>
          <Container
            component="main"
            maxWidth={false}
            sx={{
              p: [0, 2, 3],
              flexGrow: 1,
              zIndex: 1,
              position: 'relative',
              minHeight: '100vh',
            }}
          >
            {children}
          </Container>

          {requiresAuthFlow && <Footer showMinimalLayout />}
        </Box>
      </Box>
    </>
  )
}

export default LayoutMinimal
