import React, { ReactNode } from 'react'
import round from 'lodash/round'

import { Typography, TypographyProps } from '@mui/material'
import { EMISSION_EQUIVALENTS } from '../constants/emissions'

export type Unit = 't' | 'kg' | 'g'

interface FormatOptions {
  withUnit?: boolean
  equivalent?: boolean
  decimals?: number
  unitProps?: TypographyProps
}

const GRAMS_IN_TONNE = 1000000
const GRAMS_IN_KILO = 1000

/**
 * Helper for formatting emissions.
 * Use this with grams by chaining fromGrams, e.g.
 * new Emissions(1000000).fromGrams().toString() => "1 tCO₂e"
 */
class Emissions {
  static formatUnit(unit: Unit = 't', equivalent: boolean = true) {
    return `${unit}CO₂${equivalent ? 'e' : ''}`
  }

  /** The amount of emissions stored in grams */
  public amount: number

  constructor(
    /**
     * Amount can be a number (grams) or an object if the value is not in grams.
     * If an object is provided the amount will  be converted and stored as grams.
     */
    amount: number | { kg: number } | { t: number },
    /** Controls the output of how emissions are formatted via toString */
    private unit: Unit = 't',
    private locale?: string,
  ) {
    if (typeof amount === 'number') {
      this.amount = amount
    } else if (!!amount && 'kg' in amount) {
      this.amount = amount.kg * GRAMS_IN_KILO
    } else if (!!amount && 't' in amount) {
      this.amount = amount.t * GRAMS_IN_TONNE
    } else {
      this.amount = 0
    }
  }

  /**
   * Returns a new Emissions instance where the amount is converted from grams to the specified unit
   * @param unit Override the default unit for this instance
   */
  fromGrams(unit: Unit = this.unit): Emissions {
    let fraction = 1

    if (unit === 't') {
      fraction = 1 / GRAMS_IN_TONNE
    }

    if (unit === 'kg') {
      fraction = 1 / GRAMS_IN_KILO
    }

    return new Emissions(this.amount * fraction, unit, this.locale)
  }

  /**
   * Returns the emission amount as a localized, consistently formatted string.
   *
   * Set TCustomUnit to `true` when passing unitProps to format the unit.
   * This sets the correct ReactNode return type:
   *    .toString()                              --> string
   *    .toString<true>({ unitProps: { ... } })  --> ReactNode
   */
  toString<TCustomUnit extends boolean = false>(
    options?: FormatOptions,
  ): TCustomUnit extends true ? ReactNode : string
  toString(options: FormatOptions = {}) {
    const { withUnit = true, equivalent = true, decimals, unitProps } = options

    const minimumAmount = 0.001
    const showApproximation = this.amount > 0 && this.amount < minimumAmount
    const amount = showApproximation
      ? `< ${minimumAmount.toLocaleString(this.locale)}`
      : this.roundAmount(decimals)

    if (withUnit) {
      const unit = Emissions.formatUnit(this.unit, equivalent)

      if (unitProps) {
        return (
          <>
            {amount}{' '}
            <Typography component="span" {...unitProps}>
              {unit}
            </Typography>
          </>
        )
      }

      return `${amount} ${unit}`
    }

    return amount
  }

  /**
   * Returns the equivalent emissions as a relatable figure, e.g. kilometers driven
   */
  toImpactEquivalent(type: keyof typeof EMISSION_EQUIVALENTS): React.ReactNode {
    return Math.round(this.amount / EMISSION_EQUIVALENTS[type]).toLocaleString(
      this.locale,
    )
  }

  /**
   * If `decimals` is supplied, round to that number of decimal places OR
   *
   * Rounds the emission amount to:
   *  - A maximum of either 4sf or number of significant figures to the left of the decimal point (whichever is largest)
   *  - A maximum of 3 decimal places
   */
  private roundAmount(decimals?: number): string {
    const isKilosOrGrams = this.unit === 'kg' || this.unit === 'g'

    if (isKilosOrGrams && this.amount > 0) {
      /** Always round kilos or grams to a whole number and a minimum of 1 */
      const roundedAmount = Math.max(round(this.amount, decimals || 0), 1)

      return roundedAmount.toLocaleString(this.locale)
    }

    if (typeof decimals === 'number') {
      return this.amount.toLocaleString(this.locale, {
        maximumFractionDigits: decimals,
      })
    }

    const significantIntegerDigits = (Math.log10(this.amount) + 1) | 0 // Counts significant digits to the left of the decimal place
    const maximumSignificantDigits = Math.max(significantIntegerDigits, 4)

    // Round by maximumFractionDigits if the number consists only of fraction digits
    const options =
      significantIntegerDigits > 0
        ? { maximumSignificantDigits }
        : { maximumFractionDigits: 3 }

    return this.amount.toLocaleString(this.locale, options)
  }
}

export default Emissions
