import { request } from '../utils/api'
import { mapCalculationResponse } from '../utils/calculation'
import { API_ENDPOINT_PATHS } from '../constants/api'
import { Calculation } from '../types/calculation.types'

export async function fetchCalculations(
  ids: string[] = [],
): Promise<Calculation[]> {
  const responses = await request({
    type: 'GET',
    path: `${API_ENDPOINT_PATHS.CALCULATIONS}${
      ids?.length > 0 ? `?id=${ids.toString()}` : ''
    }`,
  })

  return responses.map(mapCalculationResponse)
}
