import { Box, Container, Card, Typography, Link } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

import useUiStore, { setContactFormSelector } from '../store/ui.store'
import CompensateIcon from './CompensateIcon'
import ContactFormDialog from './ContactFormDialog'
import Hero from './Hero'
import Snackbars from './Snackbars'

interface HeroImage {
  contentfulAsset: {
    gatsbyImageData: IGatsbyImageData
  }
}

function ErrorPage() {
  const image: HeroImage = useStaticQuery(graphql`
    query ErrorHero {
      contentfulAsset(contentful_id: { eq: "2eS8vvryrXg1qWte8aNp0U" }) {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  `)

  const setContactForm = useUiStore(setContactFormSelector)

  function handleContactClick() {
    setContactForm({
      category: 'support',
      metadata: {
        ErrorPage: 'true',
        Path: window.location.pathname,
      },
    })
  }
  return (
    <>
      <Snackbars />
      <Box sx={{ flexGrow: 1, zIndex: 1 }}>
        <Container
          component="main"
          maxWidth={false}
          sx={{
            minHeight: '100vh',
            p: [0, 2, 3],
            flexGrow: 1,
            zIndex: 1,
            position: 'relative',
          }}
        >
          <Hero image={image.contentfulAsset.gatsbyImageData} maxWidth="lg">
            <Card
              variant="heroAlt"
              sx={{
                textAlign: 'center',
                py: [6, null, 8],
                px: [3, 8, 12],
              }}
            >
              <CompensateIcon icon="magnify" />
              <Typography component="h1" variant="h3" sx={{ mb: 4 }}>
                Oops! Something went wrong.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 8 }}>
                We’re really sorry, something went wrong there. We keep track of
                these errors, but feel free to{' '}
                <Link
                  variant="h5"
                  onClick={handleContactClick}
                  underline="none"
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  contact us
                </Link>{' '}
                if refreshing the page doesn’t fix things.
              </Typography>
            </Card>
          </Hero>
        </Container>
      </Box>
      <ContactFormDialog />
    </>
  )
}

export default ErrorPage
