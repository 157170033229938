import * as yup from 'yup'

export type ContactCategory =
  | 'support'
  | 'feature'
  | 'invoice'
  | 'sales'
  | 'integration'
  | 'calculation'
  | 'partnership'
  | 'other'

export interface ContactFormMetadata {
  [key: string]: string
}

export interface ContactForm {
  name: string
  email: string
  companyName?: string
  category: string
  message?: string
  metadata?: ContactFormMetadata
}

interface ContactCategoryOption {
  value: ContactCategory
  label: string
}
export const CONTACT_CATEGORY: ContactCategoryOption[] = [
  { value: 'support', label: 'I need support' },
  { value: 'feature', label: 'I have a feature request' },
  { value: 'invoice', label: 'I want to pay by invoice' },
  { value: 'sales', label: "I'd like to discuss with sales" },
  { value: 'calculation', label: 'I’d like support calculating emissions' },
  {
    value: 'integration',
    label: 'I’d like to integrate compensation into my platform',
  },
  { value: 'partnership', label: 'I have a possible partnership in mind' },
  { value: 'other', label: 'Something else' },
]

export type ContactFormSchema = yup.InferType<typeof CONTACT_FORM_SCHEMA>

export const CONTACT_FORM_SCHEMA = yup
  .object({
    name: yup.string().required('Please enter a name'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter an email'),
    companyName: yup.string().optional(),
    category: yup
      .string()
      .oneOf(CONTACT_CATEGORY.map(({ value }) => value))
      .required('Please select a category'),
    message: yup.string().required('Please enter a message'),
  })
  .required()
