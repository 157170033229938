export const API_ENDPOINT_PATHS = {
  AIRPORTS: '/v1/airports',
  BILLING_DETAILS: '/v1/billing_details',
  CALCULATIONS: '/v1/calculations',
  CUSTOMER_REQUEST_ACCESS: '/v2/customers/request_access',
  CUSTOMER_REGISTER: 'v2/customers/register',
  LICENSE: '/v2/customers/license',
  API_TOKEN: '/v1/customer/token',
  PROJECTS: '/v1/projects',
  EMISSIONS_FLIGHT: '/v1/emissions/flight',
  EMISSIONS_ENERGY: '/v1/emissions/energy',
  EMISSIONS_MASS: '/v1/emissions/mass',
  EMISSIONS_PRICE: '/v1/emissions/price',
  EMISSIONS_INDEX: '/v1/emissions/index',
  EMISSIONS_PRODUCT: '/v1/emissions/product',
  IMPACT: '/v1/impact',
  IMPACT_PENDING: '/v1/impact/pending',
  INTERNAL_IMPACT: (publicId: string) => `v2/customers/${publicId}/impact`,
  INTERNAL_IMPACT_PENDING: (publicId: string) =>
    `v2/customers/${publicId}/impact/pending`,
  INTERNAL_TRANSACTIONS: (publicId: string) =>
    `v2/customers/${publicId}/transactions`,
  PAYMENT_INTENT: '/v1/payment_intents',
  TRANSACTIONS: '/v1/transactions',
  UPCOMING_INVOICE: '/v2/invoices/upcoming',
  INVOICES: '/v2/invoices',
}
