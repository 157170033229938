import React, { useState } from 'react'
import { ShoppingCart24, ArrowRight16 } from '@carbon/icons-react'
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'
import { navigate, Link } from 'gatsby'

import { addToCart } from '../apis/cart.api'
import useUiStore, { addSnackbarSelector } from '../store/ui.store'
import { Calculation } from '../types/calculation.types'
import { PAGE_PATHS } from '../constants/pages'
import { useCartCount } from '../utils/cart'
import {
  getIconFromCalculation,
  getNameFromCalculation,
} from '../utils/calculation'

interface Props extends ButtonProps {
  calculation?: Calculation
  goToCheckout?: boolean
  onAddedToCart?: () => void
  onAddingToCart?: () => void
  onFailedToAdd?: () => void
  calculationIds?: string[]
}

/**
 * Abstracts cart API interaction and loading states into a button component.
 * Supports props to allow the parent component to react to events when the
 * button is clicked, the item has been added, or failed to add.
 */
function AddToCartButton({
  calculation,
  disabled,
  goToCheckout = false,
  onAddedToCart,
  onAddingToCart,
  onFailedToAdd,
  calculationIds,
  ...props
}: Props) {
  const addSnackbar = useUiStore(addSnackbarSelector)

  const [isAddingToCart, setIsAddingToCart] = useState(false)

  async function handleAddToCart() {
    if (!calculation) {
      return
    }

    onAddingToCart?.()
    setIsAddingToCart(true)

    try {
      if (calculationIds) {
        await Promise.all(calculationIds.map((id) => addToCart(id)))
      } else {
        await addToCart(calculation.id)
      }

      onAddedToCart?.()

      if (goToCheckout) {
        navigate(PAGE_PATHS.CHECKOUT_PAYMENT)
      } else {
        setIsAddingToCart(false)
        addSnackbar(
          <AddedToCartSnackbarContent calculation={calculation} />,
          'success',
          'Added to Cart',
        )
      }
    } catch (error: any) {
      setIsAddingToCart(false)
      onFailedToAdd?.()
      addSnackbar(
        error.message ||
          'Please try again or get in touch at support@compensate.com',
        'error',
        'Failed to add to cart',
      )
    }
  }

  return (
    <Button
      data-gaid={goToCheckout ? 'pay-immediately' : 'add-to-cart'}
      variant={goToCheckout ? 'text' : 'contained'}
      startIcon={
        isAddingToCart ? (
          <CircularProgress color="inherit" />
        ) : (
          !goToCheckout && <ShoppingCart24 />
        )
      }
      onClick={handleAddToCart}
      disabled={disabled || isAddingToCart}
      {...props}
    >
      {goToCheckout ? 'Or pay immediately' : 'Add to cart'}
    </Button>
  )
}

interface AddedToCartSnackbarContentProps {
  calculation: Calculation
}
function AddedToCartSnackbarContent({
  calculation,
}: AddedToCartSnackbarContentProps) {
  const { cartCount } = useCartCount()

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box
          component={getIconFromCalculation(calculation)}
          width={40}
          height={40}
        />
        <Stack>
          <Typography variant="body2">
            {getNameFromCalculation(calculation)}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {calculation.emissions.total.fromGrams('t').toString()}
            </Typography>
            <Typography variant="body2">
              {calculation.value.fromSubunit().toString()}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          component={Link}
          to={PAGE_PATHS.CART}
          size="xSmall"
          endIcon={<ArrowRight16 />}
          fullWidth
          sx={{
            px: [2, 4],
            backgroundColor: 'success.dark',
            color: 'success.light',
            ':hover': {
              backgroundColor: 'success.main',
              color: 'success.dark',
            },
          }}
        >
          View Cart ({cartCount})
        </Button>
        <Button
          component={Link}
          color="inherit"
          fullWidth
          to={PAGE_PATHS.CHECKOUT_PAYMENT}
          size="xSmall"
          endIcon={<ArrowRight16 />}
          variant="outlined"
          sx={{ px: [2, 4] }}
        >
          Checkout
        </Button>
      </Stack>
    </Stack>
  )
}

export default AddToCartButton
