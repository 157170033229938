import React, { useEffect, useMemo, useState } from 'react'
import { NumberFormatValues } from 'react-number-format'
import { debounce } from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import InputAdornment from '@mui/material/InputAdornment'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import AlertTitle from '@mui/material/AlertTitle'
import { SxProps } from '@mui/material/styles'
import { ToggleButtonProps } from '@mui/material'

import {
  quickCalculateEmission,
  QuickCalculateType,
} from '../apis/emissions.api'
import useAuthStore, { userSelector } from '../store/auth.store'
import useUiStore, { addSnackbarSelector } from '../store/ui.store'
import NumberField from './NumberField'
import { Idle, Loading, Rejected, RequestState, Resolved } from '../utils/api'
import { Calculation } from '../types/calculation.types'
import { ApiError } from '../utils/errors'
import CalculationCostSummary from './CalculationCostSummary'

interface Props {
  onAddToCartSuccess?: () => void
  initialType?: QuickCalculateType
  withTabs?: boolean
  showSnackbarOnError?: boolean
}

const DEFAULT_TONNES = 5
const DEFAULT_EUROS = 100
const MAX_TONNES = 10000
const MAX_EUROS = 1000000

const CIRCLE_BUTTON_SX_HOVER_FOCUS_SELECTED: SxProps = {
  '&:hover, &.Mui-focusVisible': {
    borderColor: 'primary.main',
    backgroundColor: 'primary.main',
    color: 'textLight.primary',
  },
  '&.Mui-selected': {
    borderColor: 'primary.dark',
    backgroundColor: 'primary.dark',
    color: 'textLight.primary',
  },
}

const CIRCLE_BUTTON_GROUP: SxProps = {
  '& .MuiToggleButtonGroup-grouped': {
    '&:not(:first-of-type), &:first-of-type': {
      borderRadius: 10000,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: 'text.primary',
      ...CIRCLE_BUTTON_SX_HOVER_FOCUS_SELECTED,
    },
  },
}

const CIRCLE_BUTTON_SX: SxProps = {
  width: { xs: 75, md: 90 },
  height: { xs: 75, md: 90 },
  display: 'flex',
  alignContent: 'center',
  justifyItems: 'center',
  mx: [2, 4],
  borderRadius: 10000,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'text.primary',
  color: 'text.primary',
  transition:
    'background-color 100ms linear, border-color 100ms linear, color 100ms linear',
  ...CIRCLE_BUTTON_SX_HOVER_FOCUS_SELECTED,
}

const QuickCalculatorTonnes = ({
  onChangeAmount,
  errorMessage,
}: {
  onChangeAmount: (amount?: number) => void
  errorMessage?: string
}) => {
  const [tonnes, setTonnes] = React.useState<number | undefined>(DEFAULT_TONNES)

  const handleChangeTonnesFloat = (values: NumberFormatValues) => {
    setTonnes(values.floatValue)
    onChangeAmount(values.floatValue || undefined)
  }

  return (
    <Stack direction="column" alignItems="center" spacing={3}>
      <Typography
        component="p"
        variant="subtitle1"
        color="text.secondary"
        textAlign="center"
      >
        How many tonnes of CO₂e would you like to offset?
      </Typography>

      <NumberField
        label="Amount in tonnes"
        data-testid="tonnes-input"
        value={tonnes}
        onValueChange={handleChangeTonnesFloat}
        error={!!errorMessage}
        helperText={errorMessage}
        inputProps={{
          decimalScale: 2,
          max: MAX_TONNES,
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">tCO₂e</InputAdornment>,
        }}
      />
    </Stack>
  )
}

const QuickEuroAmount = ({
  value,
  ...props
}: { value: number } & Omit<ToggleButtonProps<typeof Stack>, 'value'>) => (
  <ToggleButton
    {...props}
    value={value}
    component={Stack}
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={CIRCLE_BUTTON_SX}
    data-testid="amount-toggle"
  >
    <Typography variant="h5" component="span">
      {value}
    </Typography>
    <Typography variant="body2" component="span">
      €
    </Typography>
  </ToggleButton>
)

const QuickCalculatorEuros = ({
  onChangeAmount,
  errorMessage,
}: {
  onChangeAmount: (amount?: number) => void
  errorMessage?: string
}) => {
  const [euros, setEuros] = React.useState<number | undefined>(DEFAULT_EUROS)

  const handleChangeEuros = (values: NumberFormatValues) => {
    setEuros(values.floatValue)
    onChangeAmount(values.floatValue)
  }

  const handleChangeToggleEuros = (
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue?: string,
  ) => {
    const parsedValue = newValue ? parseInt(newValue) : undefined
    setEuros(parsedValue)
    onChangeAmount(parsedValue)
  }

  return (
    <Stack direction="column" alignItems="center" spacing={3}>
      <Typography
        component="p"
        variant="subtitle1"
        color="text.secondary"
        textAlign="center"
      >
        How much would you like to spend on climate action?
      </Typography>

      <ToggleButtonGroup
        value={euros}
        exclusive
        onChange={handleChangeToggleEuros}
        aria-label="Quick select amount"
        sx={CIRCLE_BUTTON_GROUP}
      >
        <QuickEuroAmount value={100} />
        <QuickEuroAmount value={500} />
        <QuickEuroAmount value={1000} />
      </ToggleButtonGroup>

      <Typography variant="caption" color="text.label">
        Or enter a custom amount
      </Typography>

      <NumberField
        label="Amount in euros"
        data-testid="amount-input"
        value={euros}
        onValueChange={handleChangeEuros}
        error={!!errorMessage}
        helperText={errorMessage}
        inputProps={{
          decimalScale: 0,
          max: MAX_EUROS,
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
    </Stack>
  )
}

const QuickCalculator = ({
  onAddToCartSuccess,
  initialType = QuickCalculateType.Tonnes,
  withTabs = true,
  showSnackbarOnError = true,
}: Props) => {
  const user = useAuthStore(userSelector)
  const addSnackbar = useUiStore(addSnackbarSelector)
  const [calculation, setCalculation] = useState<RequestState<Calculation>>(
    new Idle(),
  )

  const [type, setType] = React.useState<QuickCalculateType>(initialType)
  const [amount, setAmount] = React.useState<number>(
    type === QuickCalculateType.Euros ? DEFAULT_EUROS : DEFAULT_TONNES,
  )

  const isRejected = calculation.status === 'REJECTED'
  const isResolved = calculation.status === 'RESOLVED'
  const errorMessage = isRejected ? calculation.error.message : undefined

  const handleCalculate = useMemo(
    () =>
      debounce(async () => {
        try {
          setCalculation(new Loading())
          const response = await quickCalculateEmission({
            type,
            amount,
          })
          setCalculation(new Resolved(response))
        } catch (error: any) {
          setCalculation(new Rejected(error as ApiError))

          if (showSnackbarOnError) {
            addSnackbar(
              <>
                <AlertTitle>Error when calculating</AlertTitle>
                {error.message || 'Please try again later!'}
              </>,
              'error',
            )
          }
        }
      }, 500),
    [addSnackbar, showSnackbarOnError, type, amount],
  )

  useEffect(() => {
    /**
     * On initial load, user change and tab change, calculate the new emissions.
     */
    setCalculation(new Loading())

    if (user) {
      handleCalculate()
    }

    return () => {
      handleCalculate.cancel()
    }
  }, [handleCalculate, user])

  function handleChangeAmount(amount?: number) {
    if (!amount) {
      setCalculation(new Rejected(new Error('Please enter an amount')))
      return
    }

    if (user) {
      setAmount(amount)
    }
  }

  function handleChangeType(newtype: QuickCalculateType) {
    setType(newtype)
    setAmount(
      newtype === QuickCalculateType.Euros ? DEFAULT_EUROS : DEFAULT_TONNES,
    )
  }

  function handleAddedToCart() {
    handleCalculate()
    onAddToCartSuccess?.()
  }

  return (
    <>
      <Box
        sx={{
          px: [4, 8, 16],
          pt: withTabs ? undefined : [6, null, 8],
          pb: [2, 4, 8],
        }}
      >
        {withTabs && (
          <Tabs
            centered
            value={type}
            onChange={(_event, newValue: QuickCalculateType) =>
              handleChangeType(newValue)
            }
          >
            <Tab value={QuickCalculateType.Tonnes} label="By Tonnes" />
            <Tab value={QuickCalculateType.Euros} label="By Euros" />
          </Tabs>
        )}

        {type === QuickCalculateType.Tonnes && (
          <QuickCalculatorTonnes
            onChangeAmount={handleChangeAmount}
            errorMessage={errorMessage}
          />
        )}

        {type === QuickCalculateType.Euros && (
          <QuickCalculatorEuros
            onChangeAmount={handleChangeAmount}
            errorMessage={errorMessage}
          />
        )}
      </Box>

      <CalculationCostSummary
        calculation={isResolved ? calculation.data : undefined}
        status={calculation.status}
        actionsDisabled={!isResolved}
        onAddedToCart={handleAddedToCart}
        sx={{
          px: [2, 4, 8],
          py: [2, 2, 4],
        }}
      />
    </>
  )
}

export default QuickCalculator
