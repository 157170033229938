import React, { forwardRef, useState } from 'react'
import {
  IconButton,
  TextField,
  InputAdornment,
  TextFieldProps,
} from '@mui/material'
import { ViewFilled20, ViewOffFilled20 } from '@carbon/icons-react'

const PasswordField = forwardRef<HTMLDivElement, TextFieldProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    function handleToggle() {
      setShowPassword((show) => !show)
    }

    return (
      <TextField
        {...props}
        ref={ref}
        type={showPassword ? 'text' : 'password'}
        label="Password"
        variant="filled"
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleToggle}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <ViewOffFilled20 /> : <ViewFilled20 />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    )
  },
)

export default PasswordField
