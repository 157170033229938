// Amount in cents
export const AMOUNT_PER_TONNE_CO2 = 3500

/**
 * Emission equivalents in grams, where the value equals one of the property key,
 * e.g. `kmDriven: 220` indicates that driving 1 kilometer emits 220gCO2e
 */
export const EMISSION_EQUIVALENTS = {
  kmDriven: 220,
  flightsAroundWorld: 8800000,
  streamingLoveActually: 150,
  squareMetersRainforestProtected: 55000,
}
