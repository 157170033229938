import React from 'react'
import { navigate } from 'gatsby'
import {
  signInWithEmailAndPassword,
  signInAnonymously,
  signOut as firebaseSignOut,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  linkWithCredential,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  linkWithRedirect,
  signInWithCredential,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { FirebaseError } from 'firebase/app'
import { AlertTitle } from '@mui/material'

import firebase from './firebase'
import useUiStore, { addSnackbarSelector } from '../store/ui.store'
import { getErrorMessage } from './error-messages'
import useAuthStore, { pickStoredUserProperties } from '../store/auth.store'
import { markTermsOfUseAccepted } from '../apis/account-flags.api'
import { registerPlatformUser } from '../apis/customer.api'
import { setAnalyticsUserId } from './analytics'
import { resetAllStores } from './store'

export function signIn(
  email: string,
  password: string,
): Promise<UserCredential> {
  return signInWithEmailAndPassword(firebase.getAuth(), email, password)
}

export async function createAnonymousAccount(): Promise<User | null> {
  let credential
  try {
    credential = await signInAnonymously(firebase.getAuth())
  } catch {
    // No-op
  }

  if (!credential) {
    return null
  }
  return credential.user
}

/**
 * If an anonymous user is currently active, upgrade that user
 * to an email account, otherwise create a new user entirely.
 */
export async function signUp(email: string, password: string) {
  const auth = firebase.getAuth()

  /**
   * If the user is anonymous "upgrade" them to a registered user
   */
  if (auth.currentUser && auth.currentUser.isAnonymous) {
    const credential = EmailAuthProvider.credential(email, password)

    await linkWithCredential(auth.currentUser, credential)

    // Update the user in state to ensure components react to an anonymous user being upgraded
    useAuthStore.setState({ user: pickStoredUserProperties(auth.currentUser) })
  } else {
    await createUserWithEmailAndPassword(auth, email, password)
  }

  try {
    await markTermsOfUseAccepted()
  } catch (error) {
    // No-op, not critical to the sign up flow so allow the user to continue if this errors
  }

  await registerPlatformUser()
}

function showSignOutSuccess() {
  const addSnackbar = addSnackbarSelector(useUiStore.getState())

  addSnackbar(<AlertTitle>You have successfully logged out</AlertTitle>)
}

export async function signOut(): Promise<void> {
  try {
    await firebaseSignOut(firebase.getAuth())
    resetAllStores()

    await navigate('/')

    setAnalyticsUserId(null)
    showSignOutSuccess()
  } catch (error) {
    // No-op
  }
}

export async function passwordReset(email: string): Promise<void> {
  try {
    await sendPasswordResetEmail(firebase.getAuth(), email)
  } catch (error) {
    // No-op
  }
}

/**
 * Checks if a user exists with the given email
 */
export async function doesEmailExist(email: string): Promise<boolean> {
  const auth = firebase.getAuth()

  try {
    const signInMethods = await fetchSignInMethodsForEmail(auth, email)

    return signInMethods.length > 0
  } catch (error) {
    return false
  }
}

/**
 * NOTE: Disabling sign UP but not sign IN is not possible with Google Sign In,
 * therefore this functionality supports both use cases even though we'd prefer
 * to prevent sign ups of new users while shutting down the service.
 *
 * Handles both signing in and signing up with Google auth provider.
 *
 * If an anonymous user is currently active, upgrade that user
 * to a Google auth provider user, otherwise create a new user entirely.
 */
export function continueWithGoogle() {
  const provider = new GoogleAuthProvider()
  const auth = firebase.getAuth()

  if (auth.currentUser && auth.currentUser.isAnonymous) {
    return linkWithRedirect(auth.currentUser, provider)
  } else {
    return signInWithRedirect(auth, provider)
  }
}

function showSignInSuccess(email?: string | null) {
  const addSnackbar = addSnackbarSelector(useUiStore.getState())

  addSnackbar(
    <>
      <AlertTitle>Welcome to Compensate's Carbon Store</AlertTitle>
      {!!email && <>You're logged in as {email}</>}
    </>,
  )
}

function showSignInError(error: Error) {
  const addSnackbar = addSnackbarSelector(useUiStore.getState())

  addSnackbar(
    <>
      <AlertTitle>Failed to log in</AlertTitle>
      {getErrorMessage(error)}
    </>,
    'error',
  )
}

/**
 * Sign in a Google Auth provider user after receiving an error from user creation.
 *
 * This should be called if the error 'auth/credential-already-in-use' occurs,
 * which indicates a user attempted to sign up with the Google Auth provider but
 * already has an account.
 */
async function signInFromUpgradeError(error: FirebaseError) {
  const auth = firebase.getAuth()
  const credential = GoogleAuthProvider.credentialFromError(error)

  try {
    if (credential) {
      await signInWithCredential(auth, credential)

      // A user has returned from Google Authentication successfully and is now signed in
      showSignInSuccess(auth.currentUser?.email)
    } else {
      showSignInError(error)
    }
  } catch (error: any) {
    showSignInError(error)
  }
}

/**
 * Checks if the user is returning from a sign in redirect via Google Authentication.
 *
 * If we tried to upgrade an anonymous user via linkWithRedirect but a user
 * has an existing account, an "auth/credential-already-in-use" error will
 * be thrown upon return to Carbon Store. This error case is handled by
 * attempting to sign the user in behind the scenes.
 */
export async function checkForRedirectSignIn() {
  const auth = firebase.getAuth()

  try {
    const result = await getRedirectResult(auth)

    // The user is not returning from a Google Authentication redirect
    if (!result) {
      return
    }

    // If a user reaches this point they have registered via the Google sign-in flow
    // Mark the user as registered in our own backend
    await registerPlatformUser()

    // A user has returned from Google Authentication successfully and is now signed up
    showSignInSuccess(auth.currentUser?.email)
  } catch (error: any) {
    /**
     * auth/credential-already-in-use indicates the user already has an account and
     * we've tried to upgrade the user from another anonymous account.
     *
     * Sign the user into their preexisting account behind the scenes.
     * TODO: Migrate anonymous basket and delete anonymous user.
     */
    if (error.code === 'auth/credential-already-in-use') {
      await signInFromUpgradeError(error as FirebaseError)
    } else {
      showSignInError(error)
    }
  }
}
