import React from 'react'
import { Box } from '@mui/system'

import LogoSVG from '../assets/svg/compensate-logo.svg'
import CarbonStoreSVG from '../assets/svg/carbon-store-logo.svg'
import GlyphSVG from '../assets/svg/compensate-glyph.svg'

interface Props {
  /** Whether the logo should collapse into the glyph on small screens */
  responsive?: boolean
  /** Whether to render the glyph instead of full logo, overrides `responsive` */
  glyph?: boolean
  /** Whether to render the carbon store variant of the full logo */
  carbonStore?: boolean
}

function getHeight(glyph: boolean, responsive: boolean) {
  if (glyph) {
    return 40
  }

  if (responsive) {
    return { md: 64, xs: 48 }
  }

  return 64
}

const Logo = ({
  responsive = true,
  glyph = false,
  carbonStore = false,
}: Props) => {
  const glyphDisplay = glyph ? 'block' : 'none'
  const logoDisplay = glyph ? 'none' : 'block'
  const height = getHeight(glyph, responsive)

  return (
    <Box sx={{ transition: 'height 0.5s', height }}>
      <Box
        component={GlyphSVG}
        sx={{
          display: glyphDisplay,
          width: 'auto',
          height: '100%',
        }}
      />

      <Box
        component={carbonStore ? CarbonStoreSVG : LogoSVG}
        sx={{
          display: logoDisplay,
          width: 'auto',
          height: '100%',
        }}
      />
    </Box>
  )
}

export default Logo
