import { Payment } from '../types/payments.types'

declare global {
  interface Window {
    // dataLayer is used by GTM. If analytics cookies are denied it'll be ignored.
    dataLayer: any[]
  }
}

export function setAnalyticsUserId(uid: string | null) {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: 'set_user_id',
    user_id: uid,
  })
}

/**
 * Creates a purchase event in Google Analytics, will only be picked up
 * by GA if the user has agreed to analytical cookies.
 */
export function trackPayment(payment: Payment) {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: 'purchase',
    transactionId: payment.id,
    transactionTotal: payment.total.amount / 100,
    transactionTax: payment.vatRate,
    transactionProducts: payment.calculations?.map((calculation) => ({
      sku: calculation.type,
      name: calculation.calculation_source || calculation.type,
      price: calculation.value.amount / 100,
      quantity: 1,
    })),
  })
}
