import React, { forwardRef } from 'react'
import Stack from '@mui/material/Stack'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import {
  AlertTitle,
  Box,
  Collapse,
  Fade,
  IconButton,
  SxProps,
  Toolbar,
} from '@mui/material'
import { CheckmarkFilled24, Close24 } from '@carbon/icons-react'
import { TransitionGroup } from 'react-transition-group'

import useUiStore, {
  removeSnackbarSelector,
  snackbarsSelector,
} from '../store/ui.store'

interface Props extends AlertProps {
  onClose: () => void
}

const Alert = forwardRef<HTMLDivElement, Props>(function Alert(
  { onClose, ...props },
  ref,
) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      sx={{ my: 1 }}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClose}
        >
          <Close24 />
        </IconButton>
      }
      iconMapping={{
        success: <CheckmarkFilled24 />,
      }}
      {...props}
    />
  )
})

const WRAPPER_SX: SxProps = {
  position: 'fixed',
  width: '100%',
  maxWidth: 516,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 'snackbar',
  px: 1,
  pointerEvents: 'none',
}

function Snackbars() {
  const snackbars = useUiStore(snackbarsSelector)
  const removeSnackbar = useUiStore(removeSnackbarSelector)

  const handleSnackbarClose = (key: string, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    removeSnackbar(key)
  }

  return (
    <Stack spacing={1} sx={WRAPPER_SX}>
      {/* Spacer to show snackbars underneath the TopBar */}
      <Toolbar />

      <TransitionGroup>
        {snackbars.map((snackbar) => (
          // Collapse alone looks crappy, for a quick fix, wrap the Alert in both Collapse and Fade
          <Collapse key={snackbar.key}>
            <Box sx={{ pointerEvents: 'initial' }}>
              <Fade in timeout={{ enter: 400 }}>
                <Alert
                  onClose={() => handleSnackbarClose(snackbar.key)}
                  severity={snackbar.severity}
                  sx={{
                    '.MuiAlert-message': {
                      width: '100%',
                    },
                  }}
                >
                  {!!snackbar.title && (
                    <AlertTitle sx={{ fontWeight: 'bold' }}>
                      {snackbar.title}
                    </AlertTitle>
                  )}

                  {snackbar.content}
                </Alert>
              </Fade>
            </Box>
          </Collapse>
        ))}
      </TransitionGroup>
    </Stack>
  )
}

export default Snackbars
