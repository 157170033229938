import { ApiError, ServerApiError } from './errors'

export type RequestType = 'POST' | 'GET' | 'PATCH'

export interface Request<T = void> {
  type: RequestType
  path: string
  data?: T
}

export interface Handlers {
  handleUnauthorized?: () => void
  handlePaymentRequired?: () => void
}

const isServerError = (httpStatusCode: number): boolean =>
  httpStatusCode >= 500 && httpStatusCode < 600

const isClientError = (httpStatusCode: number): boolean =>
  httpStatusCode >= 400 && httpStatusCode < 500

const isError = (httpStatusCode: number): boolean =>
  isServerError(httpStatusCode) || isClientError(httpStatusCode)

export async function executeFetch<R = any>(
  path: string,
  params: RequestInit,
  handlers: Handlers = {},
): Promise<R> {
  const response = await fetch(path, params)

  if (response.status === 204) {
    return response as any
  }

  if (response.status === 401) {
    handlers.handleUnauthorized?.()
  }

  if (response.status === 402) {
    handlers.handlePaymentRequired?.()
  }

  if (!isError(response.status)) {
    return response.json()
  }

  let errorObject = response

  try {
    errorObject = await response.json()
  } catch {}

  throw new ApiError(errorObject as unknown as ServerApiError, response.status)
}

export function getParams<T>(
  requestType: RequestType,
  headers: RequestInit['headers'],
  data?: T,
): RequestInit {
  if (requestType === 'GET') {
    return {
      method: 'GET',
      headers,
    }
  }

  if (requestType === 'POST' || requestType === 'PATCH') {
    return {
      method: requestType,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    }
  }

  return {}
}

/**
 * Strip leading and trailing slashes from a string
 */
export const stripSlashes = (part: string) => part.replace(/^\/|\/$/g, '')

/**
 * Utilities and types to handle different states when fetching data.
 */
export type Status = 'IDLE' | 'LOADING' | 'RESOLVED' | 'REJECTED'

interface Base {
  status: Status
}

export class Idle implements Base {
  status: 'IDLE' = 'IDLE'
}

export class Loading implements Base {
  status: 'LOADING' = 'LOADING'
}

export class Resolved<T> implements Base {
  status: 'RESOLVED' = 'RESOLVED'

  constructor(public data: T) {}
}

export class Rejected implements Base {
  status: 'REJECTED' = 'REJECTED'

  constructor(public error: ApiError | Error) {}
}

// Possible states when fetching data
export type RequestState<T> = Idle | Loading | Resolved<T> | Rejected
