import { ReactNode } from 'react'
import { AlertProps } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import { Calculation } from '../types/calculation.types'
import { ContactCategory, ContactFormMetadata } from '../types/contact-form'
import { Payment } from '../types/payments.types'
import { create } from '../utils/store'

interface Snackbar {
  key: string
  severity?: AlertProps['severity']
  content: ReactNode
  title?: string
}

/**
 * Describes part of the data used to make this calculation
 */
export interface ContextItem {
  title: string
  content: ReactNode
}

interface CalculationDrawer {
  calculation: Calculation
  /** The user input used in a calculation */
  context?: ContextItem[]
  /** When multiple calculations need to be added to the cart */
  calculationIds?: string[]
}

interface ContactForm {
  metadata?: ContactFormMetadata
  category?: ContactCategory
}

interface UiState {
  isAuthPopupOpen: boolean
  isQuickActionPopupOpen: boolean
  snackbars: Snackbar[]
  /** The current emission calculation shown in the calculation drawer */
  calculationDrawer?: CalculationDrawer
  contactForm?: ContactForm
  payments?: Payment[]
  handleOpenAuthPopup: () => void
  handleCloseAuthPopup: () => void
  handleOpenQuickActionPopup: () => void
  handleCloseQuickActionPopup: () => void
  handleAddSnackbar: (
    content: Snackbar['content'],
    severity?: Snackbar['severity'],
    title?: string,
  ) => void
  handleRemoveSnackbar: (key: string) => void
  handleSetCalculation: (
    calculation?: Calculation,
    context?: ContextItem[],
    calculationIds?: string[],
  ) => void
  handleContactForm: (contactForm?: ContactForm) => void
  handlePayments: (payments?: Payment[]) => void
}

// State selectors to avoid creating a new function on each render
export const authPopupSelector = (state: UiState) => state.isAuthPopupOpen
export const calculationSelector = (state: UiState) => state.calculationDrawer
export const contactFormSelector = (state: UiState) => state.contactForm
export const quickActionPopupSelector = (state: UiState) =>
  state.isQuickActionPopupOpen
export const snackbarsSelector = (state: UiState) => state.snackbars
export const paymentsSelector = (state: UiState) => state.payments ?? []
export const openAuthPopupSelector = (state: UiState) =>
  state.handleOpenAuthPopup
export const closeAuthPopupSelector = (state: UiState) =>
  state.handleCloseAuthPopup
export const openQuickActionPopupSelector = (state: UiState) =>
  state.handleOpenQuickActionPopup
export const closeQuickActionPopupSelector = (state: UiState) =>
  state.handleCloseQuickActionPopup
export const addSnackbarSelector = (state: UiState) => state.handleAddSnackbar
export const removeSnackbarSelector = (state: UiState) =>
  state.handleRemoveSnackbar
export const setCalculationSelector = (state: UiState) =>
  state.handleSetCalculation
export const setContactFormSelector = (state: UiState) =>
  state.handleContactForm
export const setPaymentsSelector = (state: UiState) => state.handlePayments

/**
 * The UI store contains the state of global components, for example sign in popups
 */
const useUiStore = create<UiState>((set, get) => ({
  isAuthPopupOpen: false,
  isQuickActionPopupOpen: false,
  snackbars: [],
  calculationDrawer: undefined,
  contactForm: undefined,
  payments: [],

  handleOpenAuthPopup() {
    set({
      isAuthPopupOpen: true,
    })
  },

  handleCloseAuthPopup() {
    set({
      isAuthPopupOpen: false,
    })
  },

  handleOpenQuickActionPopup() {
    set({
      isQuickActionPopupOpen: true,
    })
  },

  handleCloseQuickActionPopup() {
    set({
      isQuickActionPopupOpen: false,
    })
  },

  handleAddSnackbar(content, severity = 'success', title) {
    const key = uuidv4()
    const removeSnackbar = get().handleRemoveSnackbar

    // Automatically remove the snackbar after 10 seconds
    setTimeout(() => removeSnackbar(key), 10000)

    set({
      snackbars: [{ key, content, severity, title }, ...get().snackbars],
    })
  },

  handleRemoveSnackbar(key) {
    set({
      snackbars: get().snackbars.filter((snack) => snack.key !== key),
    })
  },

  handleSetCalculation(calculation, context, calculationIds) {
    if (!calculation) {
      // Clear the calculation drawer
      set({ calculationDrawer: undefined })
      return
    }

    set({
      calculationDrawer: {
        calculation,
        context,
        calculationIds,
      },
    })
  },

  handleContactForm(contactForm) {
    if (!contactForm) {
      // Clear the calculation drawer
      set({ contactForm: undefined })
      return
    }

    set({
      contactForm,
    })
  },

  handlePayments(payments) {
    set({
      payments,
    })
  },
}))

export default useUiStore
