import pick from 'lodash/pick'
import { onAuthStateChanged, Unsubscribe, User } from 'firebase/auth'

import firebase from '../utils/firebase'
import { checkForRedirectSignIn, createAnonymousAccount } from '../utils/auth'
import { isBot } from '../utils/environment'
import { getPlatformCustomerState } from '../apis/account.api'
import { DataSnapshot, onValue } from 'firebase/database'
import { PlatformCustomerState } from '../types/account.types'
import { setAnalyticsUserId } from '../utils/analytics'
import { create } from '../utils/store'

export type Status = 'LOADING' | 'RESOLVED' | 'REJECTED'

/**
 * Only the selected user properties from firebase.auth.User are stored in state.
 *
 * This allows us to update the entire object in state (e.g. upon upgrade from anonymous user)
 * so that components can react to user changes. If we store the entire User in state
 * it becomes more difficult to set new users as firebase.auth.currentUser follows a
 * singleton pattern which is mutated.
 */
export type StoredUser = Pick<User, 'email' | 'isAnonymous' | 'uid'>

interface AnyMetadata {
  [key: string]: string | number
}

interface AuthState {
  status: Status
  user: StoredUser | null
  watchForAuthChanges: () => void
  watchForPlatformCustomerStateChanges: (user: User) => void
  createNewUser: () => void
}

export function pickStoredUserProperties(user: User | null): StoredUser | null {
  if (!user) {
    return null
  }

  return pick(user, ['email', 'isAnonymous', 'uid'])
}

// State selectors to avoid creating a new function on each render
export const statusSelector = (state: AuthState) => state.status
export const userSelector = (state: AuthState) => state.user

let createUserTimeout: ReturnType<typeof setTimeout> | null = null
let customerStateUnsubscribe: Unsubscribe | null = null
/** For debugging purposes, will be added as metadata to user creation error logs */
const userChangelog: AnyMetadata[] = []

function addUserLog(
  action: string,
  uid: string,
  platformCustomerState: string,
) {
  userChangelog.push({
    action,
    timestamp: new Date().toISOString(),
    uid,
    platformCustomerState,
    page: window?.location?.pathname,
  })
}

/**
 * Manages the authenticated user state so that components can react to authentication changes.
 *
 * To get the current (resolved) user and react to changes within a component:
 *
 *   const user = useAuthStore(userSelector)
 */
const useAuthStore = create<AuthState>((set, get) => ({
  status: 'LOADING',
  user: null,

  /**
   * Monitor changes in the authenticated user.
   * The handler of onAuthStateChanged will be called on initial load, when signing in, and when signing out.
   *
   * If a Firebase user is not authenticated, create a new anonymous account.
   */
  async watchForAuthChanges() {
    await checkForRedirectSignIn()

    onAuthStateChanged(
      firebase.getAuth(),
      (user) => {
        if (customerStateUnsubscribe) {
          customerStateUnsubscribe()
        }

        if (user) {
          get().watchForPlatformCustomerStateChanges(user)
        } else if (!isBot) {
          get().createNewUser()
        }
      },
      () => {
        set({ user: null, status: 'REJECTED' })
      },
    )
  },

  async watchForPlatformCustomerStateChanges(user) {
    if (!user.isAnonymous) {
      /**
       * User ID is only set in analytics when a user is
       * logged in so that we can segment logged in users.
       */
      setAnalyticsUserId(user.uid)
    }

    async function handleRejected(message: string, metadata?: AnyMetadata) {
      set({ status: 'REJECTED' })

      try {
        await firebase
          .functions()
          .logger({ severity: 'ERROR', message, ...metadata })
      } catch (error) {
        // No-op
      }
    }

    function handleCustomerState(snapshot: DataSnapshot) {
      const value: PlatformCustomerState = snapshot.val()

      addUserLog('handleCustomerState', user.uid, value)

      const startCreateUserTimeout = () => {
        const timerStart = Date.now()

        createUserTimeout = setTimeout(() => {
          handleRejected(
            `Platform customer creation timed out after 15 seconds (uid: ${user.uid})`,
            {
              timerStart: new Date(timerStart).toISOString(),
              msElapsed: Date.now() - timerStart,
              cookies: `${window.cookiehub?.hasConsented?.('analytics')}`,
              uid: user.uid,
              userAgent: window.navigator?.userAgent,
              platformCustomerState: value,
              userChangelog: JSON.stringify(userChangelog),
            },
          )
        }, 15000)
      }

      if (createUserTimeout) {
        addUserLog('clearTimeout', user.uid, value)
        clearTimeout(createUserTimeout)
      }

      switch (value) {
        case 'pending':
        case null: // Indicates this is being called for the first time and platformCustomerState is empty
          startCreateUserTimeout()
          set({ status: 'LOADING' })
          break
        case 'created':
          set({ user: pickStoredUserProperties(user), status: 'RESOLVED' })
          break
        case 'failed':
        default:
          handleRejected(
            'A user with failed platform customer creation tried to visit the Carbon Store',
            {
              uid: user.uid,
              platformCustomerState: value,
            },
          )
          break
      }
    }

    customerStateUnsubscribe = onValue(
      getPlatformCustomerState(user.uid),
      handleCustomerState,
      (error) =>
        handleRejected(
          `Failed to get the user's platformCustomerState: ${error.message}`,
          { uid: user.uid },
        ),
    )
  },

  /**
   * Create a new anonymous user in Firebase Auth. A corresponding Platform User will be
   * created asynchronously by a Firebase Function listening to the user creation event.
   */
  createNewUser() {
    set({ user: null, status: 'LOADING' })
    createAnonymousAccount()
  },
}))

export default useAuthStore
