import React, { useState, MouseEvent, useEffect } from 'react'
import { UserAvatarFilledAlt32, Help32 } from '@carbon/icons-react'
import {
  Fade,
  AppBar,
  Box,
  Toolbar,
  Button,
  IconButton,
  Stack,
  useScrollTrigger,
} from '@mui/material'
import { SxProps } from '@mui/material/styles'
import { Link } from 'gatsby'

import { create } from '../utils/store'
import useUiStore, {
  openAuthPopupSelector,
  setContactFormSelector,
} from '../store/ui.store'
import useAuthStore, { userSelector } from '../store/auth.store'
import { signOut } from '../utils/auth'
import Logo from './Logo'
import UserContextMenu from './UserContextMenu'
import { isBrowser } from '../utils/environment'
import { useRequest } from '@carbon/shared'
import { License, APIAccessLevel } from '../types/license.types'
import { getLicense } from '../apis/license.api'
import { getApiDashboardAccess } from '../utils/license'

interface Props {
  showMinimalLayout?: boolean
}

const FIXED_PROPS: SxProps = {
  transition: 'background 0.1s, margin-top 0.6s',
  mt: 0,
  background: 'white',
}

const FLOATING_PROPS: SxProps = {
  transition: 'background 0.1s, margin-top 0.6s',
  mt: [4, 8],
  background: 'transparent',
}

/**
 * Material UI styling adds padding-right to the AppBar component
 * when overflow scroll is disabled in certain browsers when opening popups,
 * we need to make sure it doesn't animate in those cases,
 * so we animate padding on the toolbar
 */
const TOOLBAR_FIXED_PROPS: SxProps = {
  transition: 'padding 0.6s',
}

const TOOLBAR_FLOATING_PROPS: SxProps = {
  transition: 'padding 0.6s',
  px: [3, 9],
}

interface ApiAccessStore {
  accessLevel: APIAccessLevel
  setAccessLevel: (level: APIAccessLevel) => void
}

export const useApiAccessStore = create<ApiAccessStore>((set, get) => ({
  accessLevel: APIAccessLevel.NONE,
  setAccessLevel: (accessLevel) => set({ accessLevel }),
}))

/**
 * TopBar displays information and actions at the top of the screen.
 * TopBar initially floats with a transparent background above the hero content,
 * and when scrolling down it sticks to the top of the browser window with a solid background.
 */
const TopBar = ({ showMinimalLayout = false }: Props) => {
  const handleOpenSignIn = useUiStore(openAuthPopupSelector)
  const setContactForm = useUiStore(setContactFormSelector)
  const user = useAuthStore(userSelector)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const setAccessLevel = useApiAccessStore((store) => store.setAccessLevel)

  const license = useRequest<License>(
    getLicense,
    [user],
    (user) => !!user && !user.isAnonymous,
  )

  useEffect(() => {
    if (license.status === 'RESOLVED') {
      setAccessLevel(getApiDashboardAccess(license.data))
    }
  }, [license, setAccessLevel])

  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  })

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleClickSignIn = () => {
    setAnchorEl(null)
    handleOpenSignIn()
  }

  const handleClickSignOut = () => {
    setAnchorEl(null)
    signOut()
  }

  const handleClickHelp = () => {
    setAnchorEl(null)
    setContactForm({
      metadata: {
        Path: window.location.pathname,
      },
    })
  }

  const iconColor = scrolled ? 'text.primary' : 'common.white'
  const isSignedIn = user && !user.isAnonymous

  const helpButton = (
    <IconButton
      size="large"
      aria-label="help"
      color="inherit"
      onClick={handleClickHelp}
    >
      <Help32 />
    </IconButton>
  )

  return (
    <AppBar
      elevation={scrolled ? 2 : 0}
      sx={{
        ...(scrolled ? FIXED_PROPS : FLOATING_PROPS),
        width: '100%',
      }}
    >
      <Toolbar
        sx={{
          ...(scrolled ? TOOLBAR_FIXED_PROPS : TOOLBAR_FLOATING_PROPS),
          justifyContent: 'space-between',
          color: iconColor,
        }}
      >
        {showMinimalLayout ? (
          <Logo glyph={scrolled} carbonStore />
        ) : (
          <Box
            component={Link}
            to="/"
            sx={{
              '&, &:visited, &:active, &:hover': {
                color: iconColor,
              },
            }}
          >
            <Logo glyph={scrolled} carbonStore />
          </Box>
        )}
        {!showMinimalLayout && isBrowser && user && (
          // Hidden in SSR and before the user loads to avoid flash of other content
          <Fade in>
            <Stack direction="row" alignItems="center" spacing={1}>
              {!isSignedIn && (
                <Button
                  variant="text"
                  color="inherit"
                  onClick={handleOpenSignIn}
                  size="xSmall"
                  data-gaid="quick_account_access"
                  sx={{ display: { sm: 'inherit', xs: 'none' } }}
                >
                  Log in
                </Button>
              )}

              <Stack spacing={-0.5} direction="row" alignItems="center">
                {helpButton}

                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenMenu}
                  color="inherit"
                  sx={{
                    display: {
                      sm: isSignedIn ? 'inherit' : 'none',
                      xs: 'inherit', // Always show on mobile
                    },
                  }}
                >
                  <UserAvatarFilledAlt32 />
                </IconButton>
              </Stack>

              <UserContextMenu
                anchorEl={anchorEl}
                user={user}
                onClose={handleCloseMenu}
                onClickSignIn={handleClickSignIn}
                isApiUser={
                  license.status === 'RESOLVED'
                    ? getApiDashboardAccess(license.data) !==
                      APIAccessLevel.NONE
                    : false
                }
                onClickSignOut={handleClickSignOut}
              />
            </Stack>
          </Fade>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
