import React from 'react'
import { CircularProgress, Stack, Button, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { doesEmailExist } from '../../utils/auth'

import { Flow } from './flow.types'

const schema = yup
  .object({
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter an email'),
  })
  .required()

type Form = yup.InferType<typeof schema>

interface Props {
  onContinue: (nextFlow: Flow, email: string) => void
  /** If a user returns to the first step, initialValue contains their entered email */
  initialValue?: string
}

function InitialEmailForm({ onContinue, initialValue }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: { email: initialValue },
  })

  async function continueWithEmail(form: Form) {
    const exists = await doesEmailExist(form.email)

    onContinue(exists ? 'SIGN_IN' : 'SIGN_UP', form.email)
  }

  return (
    <Stack
      spacing={2}
      component="form"
      onSubmit={handleSubmit(continueWithEmail)}
    >
      <TextField
        {...register('email')}
        label="Email"
        inputMode="email"
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      <Button
        type="submit"
        disabled={isSubmitting}
        endIcon={isSubmitting && <CircularProgress color="inherit" />}
      >
        Next
      </Button>
    </Stack>
  )
}

export default InitialEmailForm
