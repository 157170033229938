import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { Control, FieldValues, Path, useController } from 'react-hook-form'

interface Props<TForm extends FieldValues, TName>
  extends Omit<TextFieldProps, 'name' | 'defaultValue'> {
  name: TName
  control: Control<TForm>
  defaultValue?: any
}

/**
 * ControlledTextField should be preferred over TextField whenever
 * the value is loaded asynchronously via the API.
 */
function ControlledTextField<
  TForm extends FieldValues,
  TName extends Path<TForm>,
>({ name, control, defaultValue = '', ...props }: Props<TForm, TName>) {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController<TForm, TName>({
    name,
    control,
    defaultValue,
  })

  return (
    <TextField
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      inputRef={ref}
      fullWidth
      {...props}
    />
  )
}

export default ControlledTextField
