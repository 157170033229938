import { useEffect, useState } from 'react'

import { Idle, Loading, Rejected, RequestState, Resolved } from '../utils/api'
import { ApiError } from '../utils/errors'

type PromiseOrFunc<T> = Promise<T> | (() => Promise<T>)
type Condition = (...deps: any[]) => boolean
type ReturnTypeWithSetter<T> = [
  RequestState<T>,
  React.Dispatch<React.SetStateAction<RequestState<T>>>,
]

/**
 * Abstracts loading and error functionality into a React hook, and ensures that
 * we don't set state if a promise resolves after the component has unmounted.
 *
 * Note: This is best suited for GET requests made when a component mounts,
 * for requests fired on a click or submit event just use try/ catch.
 *
 * The function signature is duplicated to allow TypeScript to resolve
 * the correct return type based on passed generics.
 */
function useRequest<T, TWithSetState extends boolean = false>(
  promiseOrFunction: PromiseOrFunc<T>,
  deps: any[],
  condition?: Condition,
  withSetState?: TWithSetState,
): TWithSetState extends true ? ReturnTypeWithSetter<T> : RequestState<T>
function useRequest<T>(
  /** The request to make. */
  promiseOrFunction: PromiseOrFunc<T>,
  /** A useEffect dependency array. If dependencies change the request will be made. */
  deps: any[],
  /**
   * An optional condition to decide whether to make the request, returning false
   * will prevent the request from being made. Receives deps as arguments.
   */
  condition?: Condition,
  /**
   * Allow state to be overridden via the returned setState function. If true,
   * useRequest will return an array similar to useState i.e. [state, setState].
   * This is useful when data could come both from the API and from user input.
   */
  withSetState: boolean = false,
): ReturnTypeWithSetter<T> | RequestState<T> {
  const [state, setState] = useState<RequestState<T>>(new Idle())

  useEffect(() => {
    let isSubscribed = true

    if (condition && !condition(...deps)) {
      return
    }

    async function makeRequest() {
      const promise =
        typeof promiseOrFunction === 'function'
          ? promiseOrFunction()
          : promiseOrFunction

      try {
        setState(new Loading())

        const response = await promise

        if (isSubscribed) {
          setState(new Resolved(response))
        }
      } catch (error: any) {
        if (isSubscribed) {
          setState(new Rejected(error as ApiError))
        }
      }
    }

    makeRequest()

    return () => {
      isSubscribed = false
    }
  }, deps)

  if (withSetState) {
    return [state, setState]
  }

  return state
}

export default useRequest
