import React from 'react'
import {
  Divider,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'

import { getCompensateCredits } from '../utils/calculation'
import CompensateCreditTooltip from './CompensateCreditTooltip'
import { Calculation } from '../types/calculation.types'
import { Status } from '../utils/api'
import { AMOUNT_PER_TONNE_CO2 } from '../constants/emissions'
import Money from '../utils/formatting/money'
import AddToCartButton from './AddToCartButton'

interface Props {
  actionsDisabled?: boolean
  calculation?: Calculation
  calculationIds?: string[]
  onAddedToCart?: () => void
  status: Status
  sx?: SxProps<Theme>
  totalLabel?: string
}

/**
 * Renders a consistent summary of the emission calculation price in
 * euros and compensate credits, and supports adding to cart/ going to checkout.
 */
function CalculationCostSummary({
  actionsDisabled,
  calculation,
  calculationIds,
  onAddedToCart,
  status,
  sx = [],
  totalLabel = 'Total',
}: Props) {
  const isLoading = status === 'IDLE' || status === 'LOADING'
  const isRejected = status === 'REJECTED'
  const isResolved = status === 'RESOLVED' && !!calculation

  const addToCartProps = {
    calculation,
    calculationIds,
    fullWidth: true,
    disabled: !isResolved || !calculation || actionsDisabled,
    onAddedToCart,
  }

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={[
        {
          py: [2, 2, 4],
          bgcolor: 'background.ivory',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <Typography variant="caption">
          Total Compensate Credits <CompensateCreditTooltip />
        </Typography>

        <Typography variant="caption">
          {isResolved && getCompensateCredits(calculation)}
          {isLoading && <Skeleton width={70} />}
          {isRejected && '—'}
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <Typography variant="caption">Price per Compensate Credit</Typography>
        <Typography variant="caption">
          {new Money(AMOUNT_PER_TONNE_CO2).fromSubunit().toString()}
        </Typography>
      </Stack>

      <Divider flexItem />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <Typography variant="body2">
          <b>{totalLabel}</b>{' '}
          <Typography color="text.label" variant="caption" component="span">
            (Excl. VAT)
          </Typography>
        </Typography>

        <Typography variant="body2">
          {isResolved && <b>{calculation.value.fromSubunit().toString()}</b>}
          {isLoading && <Skeleton width={70} />}
          {isRejected && '—'}
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <AddToCartButton {...addToCartProps} />
        <AddToCartButton goToCheckout {...addToCartProps} />
      </Stack>
    </Stack>
  )
}

export default CalculationCostSummary
