import { ref, get, set, onValue, DataSnapshot, remove } from 'firebase/database'

import { request, Request } from '../utils/api'
import { ApiError } from '../utils/errors'
import { API_ENDPOINT_PATHS } from '../constants/api'
import { PaymentIntent } from '../types/payment-intent.types'
import firebase from '../utils/firebase'
import {
  mapPaymentIntentResponse,
  mapPartialPaymentIntentResponse,
} from '../utils/payment-intent'

interface CreateUpdatePaymentIntentPayload {
  calculation_ids: string[]
  campaign_id?: string
}

export const isInvalidStateError = (error: ApiError) =>
  error.instance?.includes('invalid-state')

export const createPaymentIntent = async (
  calculationIds: string[],
  referrerId?: string,
): Promise<PaymentIntent> => {
  const referrerData = referrerId ? { referrer_id: referrerId } : undefined

  const body: Request<CreateUpdatePaymentIntentPayload> = {
    type: 'POST',
    path: API_ENDPOINT_PATHS.PAYMENT_INTENT,
    data: {
      calculation_ids: calculationIds,
      ...referrerData,
    },
  }
  const response = await request(body)

  return mapPaymentIntentResponse(response)
}

export const patchPaymentIntent = async (
  id: string,
  calculationIds: string[],
  referrerId?: string,
  customerType?: 'individual' | 'business',
  acceptedWaiver?: boolean,
): Promise<PaymentIntent> => {
  const referrerData = referrerId ? { referrer_id: referrerId } : undefined
  const customerTypeData = customerType
    ? { customer_type: customerType }
    : undefined
  const acceptedWaiverData = acceptedWaiver
    ? { accepted_waiver: `${acceptedWaiver}` }
    : undefined

  const body: Request<CreateUpdatePaymentIntentPayload> = {
    type: 'PATCH',
    path: `${API_ENDPOINT_PATHS.PAYMENT_INTENT}/${id}`,
    data: {
      calculation_ids: calculationIds,
      ...referrerData,
      ...customerTypeData,
      ...acceptedWaiverData,
    },
  }
  const response = await request(body)

  return mapPaymentIntentResponse(response)
}

export const fetchPaymentIntent = async (
  id: string,
): Promise<PaymentIntent> => {
  const body: Request = {
    type: 'GET',
    path: `${API_ENDPOINT_PATHS.PAYMENT_INTENT}/${id}`,
  }
  const response = await request(body)
  return mapPaymentIntentResponse(response)
}

export const fetchPaymentIntentEstimate = async (
  id: string,
  urlParams?: URLSearchParams,
): Promise<Partial<PaymentIntent>> => {
  const hasParams = !!urlParams?.toString()
  const body: Request = {
    type: 'GET',
    path: hasParams
      ? `${
          API_ENDPOINT_PATHS.PAYMENT_INTENT
        }/${id}/estimate?${urlParams!.toString()}`
      : `${API_ENDPOINT_PATHS.PAYMENT_INTENT}/${id}/estimate`,
  }
  const response = await request(body)
  return mapPartialPaymentIntentResponse(response)
}

export const cancelPaymentIntent = async (id: string): Promise<void> => {
  const body: Request = {
    type: 'POST',
    path: `${API_ENDPOINT_PATHS.PAYMENT_INTENT}/${id}/cancel`,
  }

  try {
    await request(body)
  } catch (error: any) {
    /**
     * If we tried to cancel a payment intent whose state does
     * not allow cancellation (e.g. if it's been paid or already
     * cancelled) ignore the error so that the old payment intent
     * is removed from the Firebase store.
     */
    if (!isInvalidStateError(error)) {
      throw error
    }
  }

  await removePaymentIntentFromStore()
}

export const storePaymentIntent = async (paymentIntent: PaymentIntent) => {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid
  if (!userId) {
    throw Error('User not found')
  }

  const paymentIntentRef = ref(db, `users/${userId}/paymentIntent`)

  await set(paymentIntentRef, {
    id: paymentIntent.id,
  })
}

export const fetchPaymentIntents = async (
  statusFilter?: 'succeeded' | 'cancelled',
): Promise<PaymentIntent[]> => {
  const body: Request = {
    type: 'GET',
    path: `${API_ENDPOINT_PATHS.PAYMENT_INTENT}${
      !!statusFilter ? `?status=${statusFilter}` : ''
    }`,
  }
  const response = await request(body)
  return response.map(mapPaymentIntentResponse)
}

/**
 * Removing the payment intent from the store
 *
 * If you provide `paymentIntentId` it will only remove the payment intent if it matches the id
 */
export async function removePaymentIntentFromStore(paymentIntentId?: string) {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid
  if (!userId) {
    throw Error('User not found')
  }

  const paymentIntentRef = ref(db, `users/${userId}/paymentIntent`)

  // If `paymentIntentId` exists, it will only remove the payment intent if it matches the id
  if (paymentIntentId) {
    const storedPaymentIntent = await get(paymentIntentRef)
    if (storedPaymentIntent.val()?.id !== paymentIntentId) {
      return
    }
  }

  await remove(paymentIntentRef)
}

export function getPaymentIntent(onChange: (snapshot: DataSnapshot) => void) {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid
  if (!userId) {
    throw Error('User not found')
  }

  const paymentIntentRef = ref(db, `users/${userId}/paymentIntent`)

  return onValue(paymentIntentRef, onChange)
}

export async function getPaymentIntentSnapshot(): Promise<DataSnapshot> {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid
  if (!userId) {
    throw Error('User not found')
  }
  return get(ref(db, `users/${userId}/paymentIntent`))
}
