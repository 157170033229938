import React from 'react'
import { PageProps } from 'gatsby'
import { Box, Container, LinearProgress } from '@mui/material'
import { useLocation } from '@reach/router'

import TopBar from './TopBar'
import Footer from './Footer'
import SignInOrUp from './login/SignInOrUp'
import Snackbars from './Snackbars'
import QuickActionPopup from './QuickActionPopup'
import useUiStore, {
  authPopupSelector,
  closeAuthPopupSelector,
  openAuthPopupSelector,
} from '../store/ui.store'
import ContactFormDialog from './ContactFormDialog'
import useAuthStore, { statusSelector, userSelector } from '../store/auth.store'
import ErrorPage from './ErrorPage'
import ErrorBoundary from './ErrorBoundary'

const useInitialIsLoginPopupOpen = (): boolean => {
  const location = useLocation()
  return location.hash === '#login'
}

interface Props extends Omit<PageProps, 'children'> {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  const initialIsLoginPopupOpen = useInitialIsLoginPopupOpen()
  const status = useAuthStore(statusSelector)
  const user = useAuthStore(userSelector)
  const isLoginPopupOpen = useUiStore(authPopupSelector)
  const handleOpenLogin = useUiStore(openAuthPopupSelector)
  const handleCloseLogin = useUiStore(closeAuthPopupSelector)

  React.useEffect(() => {
    if (initialIsLoginPopupOpen && user?.isAnonymous) {
      handleOpenLogin()
    }
  }, [initialIsLoginPopupOpen, handleOpenLogin, user])

  if (status === 'REJECTED') {
    return <ErrorPage />
  }

  return (
    <ErrorBoundary>
      {status === 'LOADING' && (
        <LinearProgress
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 'tooltip',
          }}
        />
      )}

      <Snackbars />
      <TopBar />

      <Box sx={{ flexGrow: 1, zIndex: 1 }}>
        <Container
          component="main"
          maxWidth={false}
          sx={{
            p: [0, 2, 3],
            flexGrow: 1,
            zIndex: 1,
            position: 'relative',
          }}
        >
          {children}
        </Container>

        <Footer />
      </Box>

      <SignInOrUp open={isLoginPopupOpen} onClose={handleCloseLogin} />
      <QuickActionPopup />
      <ContactFormDialog />
    </ErrorBoundary>
  )
}

export default Layout
