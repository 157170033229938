import React from 'react'
import { Box, BoxProps, SxProps } from '@mui/material'

import { getStaticAsset } from '../utils/static-asset'

type Icon =
  | 'box'
  | 'carrot'
  | 'cart'
  | 'cloud'
  | 'court'
  | 'fish'
  | 'fork'
  | 'globe'
  | 'growth'
  | 'growth_light'
  | 'house'
  | 'laptop'
  | 'leaf'
  | 'magnify'
  | 'mail'
  | 'message'
  | 'package'
  | 'phone'
  | 'plane'
  | 'sausage'

export interface CompensateIconProps extends BoxProps {
  alt?: string
  icon: Icon
  size?: 'sm' | 'md' | 'lg'
}

const getMaxWidth = (size: CompensateIconProps['size']) => {
  if (size === 'sm') {
    return '64px'
  }
  if (size === 'md') {
    return '128px'
  }

  return '160px'
}

const IMG_SX = (size: CompensateIconProps['size']): SxProps => ({
  width: '100%',
  maxWidth: getMaxWidth(size),
})

const CompensateIcon = ({ alt, icon, size, ...props }: CompensateIconProps) => (
  <Box
    sx={IMG_SX(size)}
    component="img"
    alt={alt || ''}
    size={size}
    src={getStaticAsset(`/icons/icon-${icon}.png`)}
    {...props}
  />
)

export default CompensateIcon
