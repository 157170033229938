import { Option } from './common.types'

/**
 Any addition to this array should also be made to the backend mapping of product types
 (lib/mailer/mailchimp/internal/formatting.go) to ensure consistency.
 */
const PRODUCT_TYPES: Option[] = [
  {
    value: 'index/purchases_and_goods/foods_and_beverages',
    label: 'Food & drinks',
    searchTerms: ['refreshments', 'office'],
  },
  {
    value: 'index/purchases_and_goods/small_purchases/non_daily',
    label: 'Office supplies',
    subText: 'Stationary, paper, printer ink...',
    searchTerms: ['pens', 'pencils', 'post-it notes', 'paper'],
  },
  {
    value:
      'index/purchases_and_goods/small_purchases/non_daily/books_magazines_newspapers',
    label: 'Books, magazines & newspapers',
  },
  {
    value: 'index/purchases_and_goods/big_purchases/small_electronics',
    label: 'Electronics',
    subText: 'Laptops, monitors, keyboards...',
    searchTerms: [
      'laptops',
      'printers',
      'mobiles',
      'phones',
      'screens',
      'monitors',
      'desktops',
      'computers',
    ],
  },
  {
    value: 'index/purchases_and_goods/big_purchases/furniture',
    label: 'Furniture',
    searchTerms: ['sofas', 'chairs', 'tables', 'desks'],
  },
  {
    value: 'index/purchases_and_goods/clothing_and_apparel',
    label: 'Textiles',
    searchTerms: ['curtains', 'fabrics', 'clothing', 'clothes'],
  },
  {
    value: 'index/purchases_and_goods',
    label: 'Other purchases',
  },
]

/**
 Any addition to this array should also be made to the backend mapping of product types
 (lib/mailer/mailchimp/internal/formatting.go) to ensure consistency.
 */
const SERVICE_TYPES: Option[] = [
  {
    value: 'index/social_and_entertainment/eating_and_drinking',
    label: 'Events',
    subText: 'Including meals and activities',
  },
  {
    value: 'index/housing/services/cleaning',
    label: 'Cleaning services',
    searchTerms: ['cleaner'],
  },
  {
    value: 'index/housing/services/laundry',
    label: 'Laundry services',
  },
  {
    value: 'index/housing/services/renovation',
    label: 'Renovation',
    searchTerms: ['painters', 'builders'],
  },
  {
    value: 'index/housing/utilities/telecom',
    label: 'Telecom services',
    subText: 'Internet, mobile connection...',
    searchTerms: ['phones', 'wifi', 'broadband'],
  },
  {
    value: 'index/services/other/printing',
    label: 'Printing services',
  },
  {
    value: 'index/services/health_and_wellness/healthcare',
    label: 'Healthcare services',
    searchTerms: ['medical'],
  },
  {
    value: 'index/services/other/banking_and_insurance',
    label: 'Banking and insurance services',
  },
  {
    value: 'index/services/other/postal',
    label: 'Postal services',
    searchTerms: ['posti', 'packages', 'delivery'],
  },
  {
    value: 'index/supporting_services/infrastructure/data_centers',
    label: 'Data centres',
    subText: 'Hosting, online services...',
    searchTerms: ['hosting'],
  },
  {
    value: 'index/supporting_services/infrastructure/waste',
    label: 'Waste services',
  },
  {
    value: 'index/services/other',
    label: 'Other services',
  },
  {
    value: 'request-category',
    label: 'Request category',
  },
]

/**
 Any addition to this array should also be made to the backend mapping of product types
 (lib/mailer/mailchimp/internal/formatting.go) to ensure consistency.
 */
const TRAVEL_TYPES: Option[] = [
  {
    value: 'index/transportation/public',
    label: 'Public transportation',
    subText: 'Buses, trains, trams...',
    searchTerms: ['bus', 'train', 'metro', 'ferry', 'monorail'],
  },
  {
    value: 'index/transportation/taxi',
    label: 'Taxi',
  },
  {
    value: 'index/travel/flights',
    label: 'Flights, all distances',
    searchTerms: ['plane', 'flying'],
  },
  {
    value: 'index/travel/accommodation',
    label: 'Hotels',
    searchTerms: ['airbnb', 'accommodation'],
  },
]

export const DEVICE_TYPES: Option[] = [
  {
    value: 'purchases_and_goods/big_purchases/small_electronics/laptop',
    label: 'Laptop',
    labelPlural: 'Laptops',
  },
  {
    value: 'purchases_and_goods/big_purchases/small_electronics/mobile_phone',
    label: 'Mobile phone',
    labelPlural: 'Mobile phones',
  },
  {
    value: 'purchases_and_goods/big_purchases/small_electronics/tablet',
    label: 'Tablet',
    labelPlural: 'Tablets',
  },
  {
    value: 'purchases_and_goods/big_purchases/small_electronics/monitor',
    label: 'Monitor',
    labelPlural: 'Monitors',
  },
  {
    value: 'purchases_and_goods/big_purchases/small_electronics/tv',
    label: 'TV',
    labelPlural: 'TVs',
  },
  {
    value: 'purchases_and_goods/big_purchases/small_electronics/desktop',
    label: 'Desktop computer',
    labelPlural: 'Desktop computers',
  },
]

interface PurchaseType {
  [key: string]: Option[]
}

export const PURCHASE_TYPES: PurchaseType = {
  products: PRODUCT_TYPES,
  services: SERVICE_TYPES,
  travel: TRAVEL_TYPES,
  devices: DEVICE_TYPES,
}

export const PURCHASE_TYPE_LABELS = {
  products: 'Product type',
  services: 'Service type',
  travel: 'Travel type',
  devices: 'Device type',
}

export const ADD_BUTTON_LABEL = {
  products: 'Add another product',
  services: 'Add another service',
  travel: 'Add another type',
  devices: 'Add another device',
}

export const PURCHASE_TYPE_NAMES = {
  products: {
    singular: 'product',
    plural: 'products',
  },
  services: {
    singular: 'service',
    plural: 'services',
  },
  travel: {
    singular: 'travel',
    plural: 'travels',
  },
  devices: {
    singular: 'device',
    plural: 'devices',
  },
}

export enum CategoriesWithAlternative {
  electronics = 'index/purchases_and_goods/big_purchases/small_electronics',
  flights = 'index/travel/flights',
}
