import React from 'react'
import { DialogProps } from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Box, { BoxProps } from '@mui/material/Box'
import { SxProps } from '@mui/material'
import { Close32 } from '@carbon/icons-react'

import Logo from './Logo'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

interface HeroImage {
  contentfulAsset: {
    gatsbyImageData: IGatsbyImageData
  }
}

interface Props extends BoxProps {
  onClose: DialogProps['onClose']
}

const CONTAINER_SX: SxProps = {
  userSelect: 'none',
  zIndex: 1,
  position: 'relative',
  px: [3, 4],
  py: 3,
  color: 'textLight.primary',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const GATSBY_IMAGE_SX: SxProps = {
  zIndex: -1,
  '&.gatsby-image-wrapper': {
    position: 'absolute !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}

/**
 * Display a hero image with the Compensate logo and optional close icon on Dialogs.
 */
const DialogHero = ({ onClose, ...props }: Props) => {
  const image: HeroImage = useStaticQuery(graphql`
    query DialogHero {
      contentfulAsset(contentful_id: { eq: "2jC24cvY9dyzgI1X1Nw4FE" }) {
        gatsbyImageData(width: 500, placeholder: BLURRED)
      }
    }
  `)

  return (
    <Box {...props} sx={CONTAINER_SX}>
      <Box
        component={GatsbyImage}
        image={image.contentfulAsset.gatsbyImageData}
        alt=""
        sx={GATSBY_IMAGE_SX}
      />

      <Logo />

      {!!onClose && (
        <IconButton
          size="large"
          edge="end"
          aria-label="close"
          onClick={(ev) => onClose(ev, 'backdropClick')}
          color="inherit"
        >
          <Close32 />
        </IconButton>
      )}
    </Box>
  )
}

export default DialogHero
