import React from 'react'
import {
  SxProps,
  Menu,
  MenuItem,
  Box,
  Typography,
  Link as MuiLink,
} from '@mui/material'
import { Link } from 'gatsby'

import { StoredUser } from '../store/auth.store'
import { AUTHENTICATED_USER_LINKS } from '../constants/navigation'
import { EXTERNAL_LINK_PROPS } from '../constants/links'

interface Props {
  user: StoredUser | null
  /** The element to anchor the menu to, if `null` the menu is hidden */
  anchorEl: HTMLElement | null
  isApiUser?: boolean
  onClose: () => void
  onClickSignOut: () => void
  onClickSignIn: () => void
}

const MENU_ITEM_SX: SxProps = {
  textAlign: 'right',
  justifyContent: 'flex-end',
}

const INFO_ITEM_SX: SxProps = {
  mt: -1,
  px: 2,
  py: 3,
  bgcolor: 'background.paperDark',
  ...MENU_ITEM_SX,
}

const AnonymousUserLinks = ({ onClickSignIn }: Partial<Props>) => (
  <MenuItem onClick={onClickSignIn} sx={MENU_ITEM_SX}>
    <Box>
      <Typography>Account</Typography>
      <Typography variant="body2">Log in or register</Typography>
    </Box>
  </MenuItem>
)

const RegisteredUserLinks = ({
  user,
  isApiUser = false,
  onClickSignOut,
  onClose,
}: Partial<Props>) => (
  <>
    {!!user?.email && (
      <Box component="li" sx={INFO_ITEM_SX}>
        <Typography
          component="p"
          variant="h6"
          color="textLight.primary"
          sx={{
            pl: 2,
          }}
        >
          {user.email}
        </Typography>
      </Box>
    )}

    {AUTHENTICATED_USER_LINKS.map(({ path, title, isExternal = false }) => (
      <MenuItem
        key={path}
        onClick={onClose}
        sx={MENU_ITEM_SX}
        {...(isExternal
          ? { ...(EXTERNAL_LINK_PROPS as any), component: MuiLink, href: path }
          : { component: Link, to: path })}
      >
        {title}
      </MenuItem>
    ))}

    {isApiUser && (
      <MenuItem
        key="api"
        onClick={onClose}
        sx={MENU_ITEM_SX}
        component={Link}
        to="/api"
      >
        API Integrations
      </MenuItem>
    )}

    <MenuItem onClick={onClickSignOut} sx={MENU_ITEM_SX}>
      Log out
    </MenuItem>
  </>
)

const UserContextMenu = ({
  user,
  anchorEl,
  onClose,
  onClickSignIn,
  onClickSignOut,
  isApiUser = false,
}: Props) => {
  const isAnonymous = !user || user.isAnonymous

  return (
    <Menu
      id="menu-appbar"
      PaperProps={{ variant: 'soft' }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={!!anchorEl}
      onClose={onClose}
    >
      {isAnonymous ? (
        <AnonymousUserLinks onClickSignIn={onClickSignIn} />
      ) : (
        <RegisteredUserLinks
          user={user}
          isApiUser={isApiUser}
          onClickSignOut={onClickSignOut}
          onClose={onClose}
        />
      )}
    </Menu>
  )
}

export default UserContextMenu
