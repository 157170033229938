import { ref, set, Query, push } from 'firebase/database'
import { StoredUser } from '../store/auth.store'
import { ContactForm, CONTACT_CATEGORY } from '../types/contact-form'

import firebase from '../utils/firebase'

export async function requestDistrictBuildingFeature() {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid

  if (!userId) {
    throw Error('User not found')
  }

  const requestedDistrictBuildingFeatureRef = ref(
    db,
    `feedback/districtBuildingFeature/${userId}`,
  )
  return set(requestedDistrictBuildingFeatureRef, { requested: true })
}

export function getRequestedDistrictBuildingFeatureQuery(
  user: StoredUser,
): Query {
  const db = firebase.getDatabase()
  return ref(db, `feedback/districtBuildingFeature/${user.uid}`)
}

export async function requestEmissionCategory(
  message: string,
  path: string,
  type: string,
) {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid

  if (!userId) {
    throw Error('User not found')
  }

  const requestEmissionCategoryRef = push(ref(db, `feedback/categories`))
  return set(requestEmissionCategoryRef, {
    uid: userId,
    message,
    path,
    type,
    timestamp: new Date().toISOString(),
  })
}

export async function sendContactForm({
  name,
  email,
  companyName,
  category,
  message,
  metadata,
}: ContactForm) {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid

  if (!userId) {
    throw Error('User not found')
  }

  const sendContactFormRef = push(ref(db, `feedback/contact`))
  return set(sendContactFormRef, {
    uid: userId,
    name,
    email,
    companyName: companyName ?? '',
    category:
      CONTACT_CATEGORY.find((c) => c.value === category)?.label ?? category,
    message,
    timestamp: new Date().toISOString(),
    metadata: metadata,
  })
}
