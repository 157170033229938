import React, { useState } from 'react'

import useAuthStore, { userSelector } from '../store/auth.store'
import {
  PaymentIntent,
  PaymentIntentResponse,
} from '../types/payment-intent.types'
import { getPaymentIntent } from '../apis/payment-intent.api'
import { mapCalculationResponse } from './calculation'
import DateFormatter from './formatting/date'

export const mapPaymentIntentResponse = (
  paymentIntent: PaymentIntentResponse,
): PaymentIntent => ({
  id: paymentIntent.id,
  clientSecret: paymentIntent.client_secret,
  vatRate: paymentIntent.vat_rate,
  subTotal: paymentIntent.subtotal,
  total: paymentIntent.total,
  createdAt: new DateFormatter(paymentIntent.created_at),
  succeededAt: new DateFormatter(paymentIntent.succeeded_at),
  ...(!!paymentIntent.calculations?.length && {
    calculations: paymentIntent.calculations.map(mapCalculationResponse),
  }),
  status: paymentIntent.status,
})

export const mapPartialPaymentIntentResponse = (
  paymentIntent: PaymentIntentResponse,
): Partial<PaymentIntent> => ({
  id: paymentIntent.id,
  vatRate: paymentIntent.vat_rate,
  subTotal: paymentIntent.subtotal,
  total: paymentIntent.total,
  status: paymentIntent.status,
  ...(!!paymentIntent.client_secret && {
    clientSecret: paymentIntent.client_secret,
  }),
  ...(!!paymentIntent.created_at && {
    createdAt: new DateFormatter(paymentIntent.created_at),
  }),
  ...(!!paymentIntent.succeeded_at && {
    succeededAt: new DateFormatter(paymentIntent.succeeded_at),
  }),
  ...(!!paymentIntent.calculations?.length && {
    calculations: paymentIntent.calculations.map(mapCalculationResponse),
  }),
})

export const usePaymentIntentIdentifier = (): {
  paymentIntentId: string | undefined
  isLoading: boolean
} => {
  const [paymentIntentId, setPaymentIntentId] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const user = useAuthStore(userSelector)

  React.useEffect(() => {
    let mounted = true

    if (!user) {
      return
    }

    setIsLoading(true)

    const unsubscribe = getPaymentIntent((data) => {
      if (!mounted) {
        return
      }

      setIsLoading(true)

      const storedPaymentIntent = data.val()
      if (storedPaymentIntent) {
        setPaymentIntentId(storedPaymentIntent.id)
      }

      setIsLoading(false)
    })

    return () => {
      unsubscribe()
      mounted = false
    }
  }, [user])

  return {
    paymentIntentId,
    isLoading,
  }
}

export default usePaymentIntentIdentifier
