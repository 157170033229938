import React from 'react'
import { GatsbyBrowser } from 'gatsby'

import Layout from './src/components/Layout'
import LayoutMinimal, {
  shouldShowMinimalLayout,
} from './src/components/LayoutMinimal'
import useAuthStore from './src/store/auth.store'
import { isInternalRoute } from './src/utils/environment'

/**
 * Called on first load, initialize Firebase and authentication.
 */
export function onClientEntry() {
  if (!isInternalRoute(window.location.pathname)) {
    useAuthStore.getState().watchForAuthChanges()
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const LayoutComponent = shouldShowMinimalLayout(props.location.pathname)
    ? LayoutMinimal
    : Layout

  return (
    <LayoutComponent
      requiresAuthFlow={!isInternalRoute(props.location.pathname)}
      {...props}
    >
      {element}
    </LayoutComponent>
  )
}
