import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, Container, ContainerProps, SxProps, Theme } from '@mui/material'

export interface HeroProps {
  darkenImage?: boolean
  image?: IGatsbyImageData
  maxWidth?: ContainerProps['maxWidth']
  children: React.ReactNode
  /** Using mainly text in the hero content? Ensure the background image stretches under most of the content */
  textSafe?: boolean
}

interface FallbackImage {
  contentfulAsset: {
    gatsbyImageData: IGatsbyImageData
  }
}

const HERO_PB = 4

const HERO_WRAPPER_SX: SxProps<Theme> = (theme) => ({
  position: 'relative',
  px: [0, 3],
  pb: HERO_PB,
  minHeight: '70vh',

  [theme.breakpoints.up('xxl')]: {
    minHeight: '50vh',
  },
})

const HERO_IMAGE_WRAPPER_SX: SxProps<Theme> = (theme) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: theme.spacing(HERO_PB * 3),
  zIndex: -1,
})

const DARKEN_HERO_IMAGE_SX = {
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.22)',
  },
}

function getHeroImageSx(darken: boolean, textSafe: boolean): SxProps<Theme> {
  return {
    '&.gatsby-image-wrapper': {
      position: 'sticky',
      top: [0, 16, 24],
      height: '70vh',
      minHeight: textSafe ? '85%' : 600,
      maxHeight: '100%',

      ...(darken ? DARKEN_HERO_IMAGE_SX : {}),
    },
  }
}

const Hero = ({
  image,
  maxWidth = 'lg',
  children,
  darkenImage = false,
  textSafe = false,
}: HeroProps) => {
  const fallback: FallbackImage = useStaticQuery(graphql`
    query FallbackImage {
      contentfulAsset(title: { eq: "Default hero" }) {
        ...HeroFragment
      }
    }
  `)

  const imageData = image || fallback.contentfulAsset.gatsbyImageData

  return (
    <Box sx={HERO_WRAPPER_SX}>
      <Box sx={HERO_IMAGE_WRAPPER_SX}>
        {!!imageData && (
          <Box
            sx={getHeroImageSx(darkenImage, textSafe)}
            component={GatsbyImage}
            image={imageData}
            alt=""
          />
        )}
      </Box>

      <Container maxWidth={maxWidth} sx={{ pt: 25 }}>
        {children}
      </Container>
    </Box>
  )
}

/**
 * graphql fragment for consistent hero image formatting
 */
export const heroFragment = graphql`
  fragment HeroFragment on ContentfulAsset {
    gatsbyImageData(
      layout: FULL_WIDTH
      quality: 80
      placeholder: BLURRED
      formats: [AUTO, WEBP]
    )
  }
`

export default Hero
