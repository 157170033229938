import { ref, set, Query } from 'firebase/database'
import { StoredUser } from '../store/auth.store'

import firebase from '../utils/firebase'

const FLAGS = {
  TERMS: 'termsOfUse202204',
}

export async function markTermsOfUseAccepted() {
  const db = firebase.getDatabase()
  const userId = firebase.getAuth().currentUser?.uid

  if (!userId) {
    throw Error('User not found')
  }

  const termsRef = ref(db, `users/${userId}/flags/${FLAGS.TERMS}`)

  return set(termsRef, true)
}

export function getTermsQuery(user: StoredUser): Query {
  const db = firebase.getDatabase()
  return ref(db, `users/${user.uid}/flags/${FLAGS.TERMS}`)
}
