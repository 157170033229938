import React from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import {
  TextFieldProps,
  MenuItem,
  TextField,
  Typography,
  Stack,
  Box,
} from '@mui/material'

interface Option<T = string> {
  value: T
  label: string
  subText?: string
}

interface Props<TForm extends FieldValues, TName>
  extends Omit<TextFieldProps, 'name' | 'defaultValue'> {
  name: TName
  control: Control<TForm>
  options: Option[]
  defaultValue?: any
  renderActionButton?: JSX.Element
}

function ControlledSelect<
  TForm extends FieldValues,
  TName extends Path<TForm>,
>({
  control,
  name,
  defaultValue,
  options,
  renderActionButton,
  ...props
}: Props<TForm, TName>) {
  const [isOpen, setIsOpen] = React.useState(false)
  const {
    field: { onChange, onBlur, value, ref },
  } = useController<TForm, TName>({
    name,
    control,
    defaultValue,
  })

  return (
    <TextField
      {...props}
      inputRef={ref}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      select
      SelectProps={{
        open: isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
        renderValue: (value) =>
          options.find((option) => option.value === value)?.label,
      }}
    >
      {options.map(({ label, value, subText }) => (
        <MenuItem key={value} value={value}>
          <Stack>
            {label}
            <Typography variant="caption" color="text.label">
              {subText}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
      <Box onClick={() => setIsOpen(false)}>{renderActionButton}</Box>
    </TextField>
  )
}

export default ControlledSelect
