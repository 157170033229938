import React from 'react'
import { useForm } from 'react-hook-form'
import {
  IconButton,
  Typography,
  Stack,
  Button,
  CircularProgress,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowLeft20 } from '@carbon/icons-react'
import * as yup from 'yup'

import { signIn, signUp } from '../../utils/auth'
import useUiStore, { addSnackbarSelector } from '../../store/ui.store'
import {
  getErrorMessage,
  getPasswordErrorMessage,
} from '../../utils/error-messages'
import { Flow } from './flow.types'
import PasswordField from './PasswordField'

const schema = yup
  .object({
    password: yup
      .string()
      .test('len', 'Passwords must have at least 6 characters', (pass) =>
        typeof pass === 'string' ? pass.length >= 6 : false,
      )
      .required('Please enter a password'),
    termsAgreement: yup
      .bool()
      .when('$flow', (flow: Flow, schema) =>
        flow === 'SIGN_UP'
          ? schema
              .oneOf(
                [true],
                'Please agree to the Terms of Use before creating an account',
              )
              .required()
          : schema,
      ),
  })
  .required()

type Form = yup.InferType<typeof schema>

interface Props {
  flow: Flow
  email: string
  onBack: () => void
  onForgotPassword: () => void
  /** Called when the user has signed in or up successfully */
  onComplete: () => void
}

/**
 * Simple form with a password input and "Register" or "Log in" button
 * depending on the current flow. Allows the user to return to the initial
 * step in case they entered their email incorrectly.
 */
function ContinuedWithEmailForm({
  flow,
  email,
  onBack,
  onForgotPassword,
  onComplete,
}: Props) {
  const addSnackbar = useUiStore(addSnackbarSelector)
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<Form>({
    context: { flow },
    resolver: yupResolver(schema),
  })

  async function signInOrUp(form: Form) {
    try {
      if (flow === 'SIGN_IN') {
        await signIn(email, form.password)
      } else if (flow === 'SIGN_UP') {
        await signUp(email, form.password)
      }

      onComplete()
    } catch (error: any) {
      const passwordMessage = getPasswordErrorMessage(error)

      if (passwordMessage) {
        // If a password error was caught, show the error against the password field
        setError('password', {
          type: 'auth',
          message: getPasswordErrorMessage(error),
        })

        return
      }

      // If a generic error occurred, show an error snackbar
      addSnackbar(getErrorMessage(error), 'error')
    }
  }

  const [prefix, domain] = [
    email.substring(0, email.indexOf('@')),
    email.substring(email.indexOf('@')),
  ]

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(signInOrUp)}>
      {flow === 'SIGN_UP' && (
        <Typography paragraph>
          The Carbon Store is no longer accepting new registrations, we
          apologize for the inconvenience.
        </Typography>
      )}

      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton onClick={onBack}>
          <ArrowLeft20 />
        </IconButton>
        <Typography textAlign="left">
          {prefix}
          <wbr />
          {domain}
        </Typography>
      </Stack>

      {flow === 'SIGN_IN' && (
        <>
          <PasswordField
            {...register('password')}
            autoFocus // Auto focus for smoother log in
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <Button
            type="submit"
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress color="inherit" />}
            data-gaid="login_with_email"
          >
            Log in
          </Button>
          <Button
            variant="text"
            size="small"
            disabled={isSubmitting}
            onClick={onForgotPassword}
          >
            Forgot password?
          </Button>
        </>
      )}
    </Stack>
  )
}

export default ContinuedWithEmailForm
