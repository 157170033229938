import React from 'react'
import { Link, LinkProps } from '@mui/material'

declare global {
  interface Window {
    // cookiehub is injected by the CookieHub script
    cookiehub: any
  }
}

interface Props {
  typography?: LinkProps['typography']
  color?: LinkProps['color']
  fontWeight?: LinkProps['fontWeight']
  underline?: LinkProps['underline']
}

/**
 * For services that use third party cookies, we manage consent with CookieHub.
 * This component should be only used if the CookieHub script is installed on a page,
 * either directly or via Google Tag Manager.
 */
const ChangeCookieHubConsent = (props: Props) => {
  const handleRevokeCookieConsent = () => {
    if (window.cookiehub) {
      window.cookiehub.openSettings()
    }
  }

  return (
    <Link
      typography="body2"
      {...props}
      component="button"
      onClick={handleRevokeCookieConsent}
    >
      Change cookie consent
    </Link>
  )
}

export default ChangeCookieHubConsent
