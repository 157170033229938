import {
  executeFetch,
  getParams,
  Handlers,
  Request,
  stripSlashes,
} from '@carbon/shared'

import firebase from './firebase'

export async function request<T, R = any>(
  requestConfig: Request<T>,
  handlers?: Handlers,
): Promise<R> {
  const root = process.env.GATSBY_API_ROOT || ''
  const token = await firebase.getToken()
  const url = `${stripSlashes(root)}/${stripSlashes(requestConfig.path)}`

  if (!root) {
    console.error('Required GATSBY_API_ROOT not defined')
  }

  const headers: RequestInit['headers'] = {
    accept: 'application/json',
  }

  if (token) {
    headers.authorization = `Bearer ${token}`
  }

  const params = getParams<T>(requestConfig.type, headers, requestConfig.data)

  return executeFetch<R>(url, params, handlers)
}

export * from '@carbon/shared/utils/api'
