import React from 'react'
import {
  Api32,
  EarthFilled32,
  ScalesTipped32,
  Launch16,
} from '@carbon/icons-react'
import { Stack } from '@mui/material'

import {
  COMPENSATE_COM_LINK,
  CARBON_STORE_LINK,
  PRIVACY_STATEMENT_LINK,
  TOU_LINK,
} from './links'

export interface NavigationLink {
  title: string | JSX.Element
  path: string
  icon?: JSX.Element
  isExternal?: boolean
}

export const NAVIGATION_LINKS: NavigationLink[] = [
  {
    icon: <ScalesTipped32 />,
    title: 'Offset',
    path: '/',
  },
  {
    icon: <EarthFilled32 />,
    title: 'Impact',
    path: '/impact',
  },
  {
    icon: <Api32 />,
    title: 'API',
    path: '/api',
  },
]

export const AUTHENTICATED_USER_LINKS: NavigationLink[] = [
  {
    title: 'Impact Report',
    path: '/impact',
  },
  {
    title: 'Account',
    path: '/account',
  },
  {
    title: 'Payments & Billing',
    path: '/account/billing',
  },
  {
    title: 'Resources & Support',
    path: '/account/resources',
  },
]

export const FOOTER_LINKS: NavigationLink[] = [
  {
    title: 'Home',
    path: '/',
  },

  {
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <span>Compensate.com</span>
        <Launch16 />
      </Stack>
    ),
    path: COMPENSATE_COM_LINK,
    isExternal: true,
  },
]

export const FOOTER_LINKS_MINIMAL: NavigationLink[] = [
  {
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <span>Compensate.com</span>
        <Launch16 />
      </Stack>
    ),
    path: COMPENSATE_COM_LINK,
    isExternal: true,
  },
  {
    title: (
      <Stack direction="row" spacing={1} alignItems="center">
        <span>Carbon Store</span>
        <Launch16 />
      </Stack>
    ),
    path: CARBON_STORE_LINK,
    isExternal: true,
  },
]

export const FOOTER_BOTTOM_LINKS: NavigationLink[] = [
  {
    title: 'Privacy Statement',
    path: PRIVACY_STATEMENT_LINK,
    isExternal: true,
  },
  {
    title: 'Terms of Use',
    path: TOU_LINK,
    isExternal: true,
  },
]
