import { FirebaseError } from 'firebase/app'

function isFirebaseError(error: Error) {
  return !!(error as FirebaseError).code
}

export function getPasswordErrorMessage(error: Error) {
  if (isFirebaseError(error)) {
    const firebaseError = error as FirebaseError

    switch (firebaseError.code) {
      case 'auth/wrong-password':
        return "The password is invalid or you don't have a password"
      case 'auth/invalid-password':
        return 'The password must be a string with at least 6 characters.'
      case 'auth/weak-password':
        return 'The password must be 6 characters long or more.'
      default:
        // Error is not password related
        return undefined
    }
  }

  return undefined
}

export function getErrorMessage(error: Error) {
  if (isFirebaseError(error)) {
    const firebaseError = error as FirebaseError

    switch (firebaseError.code) {
      case 'auth/network-request-failed':
      case 'network-request-failed':
        return 'A network error has occurred, please try again in a moment.'
    }
  }

  return error.message
}
