import React from 'react'
import { CircularProgress, Stack, Button, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { passwordReset } from '../../utils/auth'

const schema = yup
  .object({
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter an email'),
  })
  .required()

type Form = yup.InferType<typeof schema>

interface Props {
  onBack: () => void
  onContinue: (email: string) => void
  initialEmailValue?: string
}

function ForgotPasswordForm({ onBack, onContinue, initialEmailValue }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: { email: initialEmailValue },
  })

  async function handleResetPassword(form: Form) {
    await passwordReset(form.email)

    onContinue(form.email)
  }

  return (
    <Stack
      spacing={2}
      component="form"
      onSubmit={handleSubmit(handleResetPassword)}
    >
      <TextField
        {...register('email')}
        label="Email"
        inputMode="email"
        error={!!errors.email}
        helperText={errors.email?.message}
      />

      <Button
        type="submit"
        disabled={isSubmitting}
        endIcon={isSubmitting && <CircularProgress color="inherit" />}
      >
        Reset password
      </Button>

      <Button
        variant="text"
        size="small"
        disabled={isSubmitting}
        onClick={onBack}
      >
        Cancel
      </Button>
    </Stack>
  )
}

export default ForgotPasswordForm
