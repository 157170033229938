import React, { useState, useEffect, useCallback } from 'react'
import { sumBy } from 'lodash'
import AlertTitle from '@mui/material/AlertTitle'

import { fetchCalculations } from '../apis/calculations.api'
import { Cart, CartItem, getCart, getCartQuery } from '../apis/cart.api'
import useAuthStore, { userSelector } from '../store/auth.store'
import useUiStore, { addSnackbarSelector } from '../store/ui.store'
import { Calculation } from '../types/calculation.types'
import Emissions from './formatting/emissions'
import useRealtimeDBOnChange from '../hooks/useRealtimeDBOnChange'
import { PaymentIntent } from '../types/payment-intent.types'
import {
  cancelPaymentIntent,
  createPaymentIntent,
  getPaymentIntentSnapshot,
  isInvalidStateError,
  patchPaymentIntent,
  storePaymentIntent,
} from '../apis/payment-intent.api'
import firebase from './firebase'

export const mapFirebaseCartToCartItems = (
  cart: Cart,
  calculations?: Calculation[],
): CartItem[] =>
  cart instanceof Object
    ? Object.entries(cart).map(([cartItemId, calculationId]) => ({
        calculationId,
        calculation: calculations
          ? calculations.find((calc) => calc.id === calculationId)
          : undefined,
        itemId: cartItemId,
      }))
    : []

export const getTotalOfCartItems = (
  items: CartItem[],
  type: 'emissions' | 'value',
): number =>
  sumBy(
    items,
    `calculation.${type}${type === 'emissions' ? '.total' : ''}.amount`,
  ) || 0

export const useCartCount = (): {
  isLoading: boolean
  cartCount: number
} => {
  const [cartCount, setCartCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const user = useAuthStore(userSelector)

  useEffect(() => {
    let mounted = true

    if (!user) {
      return
    }

    setIsLoading(true)

    const unsubscribe = getCart(async (cartItems) => {
      if (!mounted) {
        return
      }

      const cart: Cart | undefined = cartItems.val()
      setCartCount(cart ? Object.entries(cart).length : 0)

      setIsLoading(false)
    })

    return () => {
      unsubscribe()
      mounted = false
    }
  }, [user])

  return {
    isLoading,
    cartCount,
  }
}

function logPaymentIntentError(
  error: any,
  type: 'create' | 'patch' | 'cancel',
  userId?: string,
) {
  firebase.functions().logger({
    severity: 'ERROR',
    message: `Failed to ${type} payment intent: ${error.message}`,
    uid: userId,
    error: JSON.stringify(error, Object.getOwnPropertyNames(error), 2),
  })
}

export const useCart = (): {
  cart: CartItem[]
  calculationIds: string[]
  totalEmissions: Emissions
  isLoading: boolean
  isEmptyCart: boolean
  isCartFirstLoad: boolean
  paymentIntent: PaymentIntent | undefined
  setCart: (cartItems: CartItem[]) => void
  setPaymentIntent: (paymentIntent: PaymentIntent | undefined) => void
  setIsLoadingPaymentIntent: (isLoading: boolean) => void
} => {
  const user = useAuthStore(userSelector)
  const addSnackbar = useUiStore(addSnackbarSelector)

  const [cart, setCart] = useState<CartItem[]>([])
  const [paymentIntent, setPaymentIntent] = useState<
    PaymentIntent | undefined
  >()
  const [isCartFirstLoad, setIsCartFirstLoad] = useState(true)
  const [runningRequests, setRunningRequests] = useState(0)
  const [isLoadingPaymentIntent, setIsLoadingPaymentIntent] = useState(true)

  const cartRequestState = useRealtimeDBOnChange<Cart>(getCartQuery)

  const createPaymentIntentRequest = useCallback(
    async (calculationIds: string[]) => {
      try {
        setRunningRequests((prev) => prev + 1)
        const newPaymentIntent = await createPaymentIntent(calculationIds)
        setPaymentIntent(newPaymentIntent)
        await storePaymentIntent(newPaymentIntent)
      } catch (error: any) {
        logPaymentIntentError(error, 'create', user?.uid)
        addSnackbar(
          <>
            <AlertTitle>Something went wrong while loading the cart</AlertTitle>
            {error.message || 'Please try again later'}
          </>,
          'error',
        )
      } finally {
        setRunningRequests((prev) => prev - 1)
      }
    },
    [addSnackbar, user?.uid],
  )

  const patchPaymentIntentRequest = useCallback(
    async (calculationIds: string[], paymentIntentId: string) => {
      try {
        setRunningRequests((prev) => prev + 1)
        const newPaymentIntent = await patchPaymentIntent(
          paymentIntentId,
          calculationIds,
        )
        setPaymentIntent(newPaymentIntent)
      } catch (error: any) {
        logPaymentIntentError(error, 'patch', user?.uid)

        /**
         * If we tried to patch a payment intent whose state does
         * not allow updating (e.g. if it's been paid or already
         * cancelled) ignore the error and create a fresh payment
         * intent to prevent the user from getting stuck.
         */
        if (isInvalidStateError(error)) {
          await createPaymentIntentRequest(calculationIds)
          return
        }

        addSnackbar(
          <>
            <AlertTitle>Something went wrong while loading the cart</AlertTitle>
            {error.message || 'Please try again later'}
          </>,
          'error',
        )
      } finally {
        setRunningRequests((prev) => prev - 1)
      }
    },
    [addSnackbar, createPaymentIntentRequest, user?.uid],
  )

  const removePaymentIntentRequest = useCallback(
    async (paymentIntentId: string) => {
      try {
        setRunningRequests((prev) => prev + 1)
        await cancelPaymentIntent(paymentIntentId)
        setPaymentIntent(undefined)
      } catch (error: any) {
        logPaymentIntentError(error, 'cancel', user?.uid)
        // No-op
      } finally {
        setRunningRequests((prev) => prev - 1)
      }
    },
    [user?.uid],
  )

  const handlePaymentIntent = useCallback(
    async (cart: CartItem[]) => {
      const paymentIntentIdDataSnapshot = await getPaymentIntentSnapshot()
      const paymentIntentId: string = paymentIntentIdDataSnapshot.val()?.id
      const calculationIds = cart.map((item) => item.calculationId)

      if (calculationIds.length && !paymentIntentId) {
        await createPaymentIntentRequest(calculationIds)
      } else if (calculationIds.length && !!paymentIntentId) {
        await patchPaymentIntentRequest(calculationIds, paymentIntentId)
      } else if (!calculationIds.length && !!paymentIntentId) {
        await removePaymentIntentRequest(paymentIntentId)
      }
    },
    [
      createPaymentIntentRequest,
      patchPaymentIntentRequest,
      removePaymentIntentRequest,
    ],
  )

  useEffect(() => {
    if (!!user && cartRequestState.status === 'RESOLVED') {
      const cart = cartRequestState.data ?? ({} as Cart)
      const formattedCart = mapFirebaseCartToCartItems(cart)
      const calculationIds: string[] = formattedCart.map(
        (item) => item.calculationId,
      )

      ;(async () => {
        try {
          const customerCalculations = await fetchCalculations(calculationIds)
          const mappedCart = mapFirebaseCartToCartItems(
            cart,
            customerCalculations,
          )
          setCart(mappedCart)
          await handlePaymentIntent(mappedCart)
          setIsCartFirstLoad(false)
        } catch (error: any) {
          addSnackbar(
            <>
              <AlertTitle>
                Something went wrong while loading the cart
              </AlertTitle>
              {error.message || 'Please try again later'}
            </>,
            'error',
          )
        }
      })()
    }
  }, [cartRequestState, handlePaymentIntent, addSnackbar, user])

  useEffect(() => {
    setIsLoadingPaymentIntent(runningRequests > 0)
  }, [runningRequests])

  const isLoading =
    cartRequestState.status === 'LOADING' ||
    isCartFirstLoad ||
    isLoadingPaymentIntent

  return {
    cart,
    calculationIds: cart.map((item) => item.calculationId),
    totalEmissions: new Emissions(getTotalOfCartItems(cart, 'emissions')),
    isLoading,
    isEmptyCart: !isLoading && !cart?.length,
    isCartFirstLoad,
    paymentIntent,
    setCart,
    setPaymentIntent,
    setIsLoadingPaymentIntent,
  }
}

export default useCart
