import React from 'react'
import { useLocation } from '@reach/router'
import { Box } from '@mui/material'

import useUiStore, {
  quickActionPopupSelector,
  openQuickActionPopupSelector,
  closeQuickActionPopupSelector,
} from '../store/ui.store'
import DialogWrapper from './DialogWrapper'
import QuickCalculator from './QuickCalculator'

const useInitialIsQuickActionPopupOpen = (): boolean => {
  const location = useLocation()
  return location.hash === '#purchase'
}

const QuickActionPopup = () => {
  const initialIsQuickActionPopupOpen = useInitialIsQuickActionPopupOpen()
  const isOpen = useUiStore(quickActionPopupSelector)
  const handleOpen = useUiStore(openQuickActionPopupSelector)
  const handleClose = useUiStore(closeQuickActionPopupSelector)

  React.useEffect(() => {
    if (initialIsQuickActionPopupOpen) {
      handleOpen()
    }
  }, [initialIsQuickActionPopupOpen, handleOpen])

  return (
    <DialogWrapper
      onClose={handleClose}
      open={isOpen}
      maxWidth="sm"
      aria-label="purchase compensate carbon credits to offset emissions"
      hasContentPadding={false}
      sx={{ textAlign: 'center' }}
    >
      <Box pt={4}>
        <QuickCalculator onAddToCartSuccess={handleClose} />
      </Box>
    </DialogWrapper>
  )
}

export default QuickActionPopup
